.SendForgotPassEmail-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  /*  justify-content: center; */
  min-height: 48px;
  margin: 0;
}

.forgot-password.modal {
  overflow-y: hidden;
}

.forgotPass.modal-body {
  padding: 0;
  margin: 0;
}

.forgotPass.modal-footer .btn {
  font-size: 16px;
}

.forgotPass.container {
  min-height: 0;
  padding: 0;
  margin: 0;
}

.SendForgotPassEmail-form.authentication-form .col .authentication-error {
  text-align: center;
  /* background-color: #ff4d4d; */

  margin-bottom: 0;
}

.SendForgotPassEmail-form.authentication-form {
  margin-top: 2%;
}

.SendForgotPassEmail-form.authentication-form p {
  margin-left: 3%;
}

.SendForgotPassEmail-form .validation-error-msg {
  color: red;
  font-size: 12px;
  margin-top: 2%;
}

input.SendForgotPassEmail.form-control {
  border: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: lightgrey;
  color: black;
}

input.SendForgotPassEmail.form-control.error {
  border-bottom: 1px solid red;
}

input.SendForgotPassEmail.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

input.SendForgotPassEmail:-webkit-autofill,
input.SendForgotPassEmail:-webkit-autofill:hover,
input.SendForgotPassEmail:-webkit-autofill:focus,
input.SendForgotPassEmail:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-light-gray) inset !important;
  /*  -webkit-text-fill-color: white; */
}

input.SendForgotPassEmail :-webkit-autofill-selected {
  color: var(--secondary-light-gray);
}
