form.profile-picture-form .profile-picture {
  width: 300px;
  height: 300px;
}

form.profile-picture-form .row .col {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

form.profile-picture-form .row .col #upload-picture,
#remove-picture {
  margin-top: 1px;
}

form.profile-picture-form .row .col .space-between {
  width: 100px;
}

form.profile-picture-form #profile-picture-input {
  display: none !important;
}
