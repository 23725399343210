table.actionsTable {
  align-self: center;
}

table.actionsTable > thead > tr,
table.actionsTable > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.actionsTable > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.actionsTable > thead > tr > th:first-child,
table.actionsTable > tbody > tr > td:first-child,
table.actionsTable > tbody > tr > td:nth-child(2) {
  text-align: left;
}

table.actionsTable > tbody > tr {
  background-color: #fff;
  height: 6.75rem;
}

table.actionsTable > tbody > tr.inactive,
table.actionsTable > tbody > tr.inactive > td > .btn-link {
  color: #c1c1c1;
}

table.actionsTable > tbody > tr > td {
  padding: 0.25rem 1rem 1.5rem 1rem;
  text-align: center;
}

table.actionsTable > tbody > tr > td:nth-child(2) {
  width: 18rem;
}

table.actionsTable > tbody > tr > td > .employee-name {
  font-size: 1rem;
  font-weight: bold;
}

table.actionsTable > tbody > tr > td > .employee-position {
  font-size: 0.875rem;
}

table.actionsTable > tbody > tr > td > .employee-email {
  font-size: 0.75rem;
  font-style: italic;
}

table.actionsTable > tbody > tr > td > .appraisal-title,
table.actionsTable > tbody > tr > td > .review-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.actionsTable > tbody > tr > td > .actions {
  position: absolute;
}

table.actionsTable > tbody > tr > td > .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.actionsTable > tbody > tr > td > .actions > div:first-child > small {
  padding-top: 0.5rem;
}

table.actionsTable > tbody > tr > td > .actions svg {
  vertical-align: 0;
}

table.actionsTable > tbody > tr.change-on-hover {
  height: 6.5rem;
}

table.actionsTable > tbody > tr.change-on-hover > td {
  padding: 0.25rem 1rem;
}

table.actionsTable > tbody > tr.change-on-hover:hover {
  background-color: #6691ff26;
  cursor: pointer;
}
