.msts_theme_example {
  /* border: 1px solid silver; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .msts__heading {
    display: flex;

    .msts__side {
      padding: 5px;
      text-align: center;
      color: black;
      font-weight: bold;
    }

    .msts__side_available {
      color: #2d2f66;
      width: 50%;
    }

    .msts__side_selected {
      color: #2d2f66;
      width: 50%;
      float: right;
    }
  }

  .msts__subheading {
    display: flex;
    border-radius: 5px;

    .msts__side_filter {
      padding: 5px;
      width: 50%;
    }
  }

  .msts__body {
    margin-top: 1%;
    display: flex;

    .msts__side.msts__side_available {
      width: 45%;
      border: 1px solid silver;
      border-radius: 3px;
      margin-left: 1%;
    }

    .msts__side.msts__side_selected {
      width: 45%;
      border: 1px solid silver;
      border-radius: 3px;
      margin-right: 1%;
    }

    .msts__side.msts__side_controls {
      width: 10%;
      left: 3%;
      position: relative;
    }
  }

  .msts__footer {
    .msts__side {
      padding: 5px 15px;
      background-color: #ecf0f1;
      font-size: 0.75em;
      color: #7f8c8d;
      text-align: right;
    }
  }

  .msts__list {
    height: 199px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    list-style-type: none;
  }

  .msts__list-item {
    padding: 0;
    transition: background-color ease-in 0.1s, color ease-in 0.1s;

    &:hover {
      background-color: #6691ff;
      color: #fff;
    }

    &:disabled {
      background-color: #ecf0f1;
      color: #7f8c8d;

      &:hover {
        background-color: #ecf0f1;
        color: #7f8c8d;
      }
    }

    &_highlighted {
      background-color: #2d2f66;
    }
  }

  .msts__list-item:disabled {
    background-color: #ecf0f1;
    color: #7f8c8d;
  }

  .msts__list-item:disabled:hover {
    background-color: #ecf0f1;
    color: #7f8c8d;
  }

  .msts__control {
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px 3px;
    color: #bdc3c7;
    transition: color ease 0.15s;

    &:hover {
      color: #95a5a6;
    }

    &[disabled] {
      color: #696784;
    }
  }

  .msts_theme_example .msts__control:disabled {
    color: #696784;
  }

  .msts__control_select-all {
    &:after {
      content: "\276F";
      color: #6691ff;
    }
    &:hover {
      color: #2d2f66;
    }
  }

  .msts__control_select-all:disabled {
    color: #696784;
    pointer-events: none;
  }

  .msts__control_deselect-all:disabled {
    color: #696784;
    pointer-events: none;
  }

  .msts__control.msts__control_select-all:focus {
    outline: none;
  }

  .msts__control.msts__control_deselect-all:focus {
    outline: none;
  }

  .msts__filter-input:focus-visible {
    outline-color: var(--primary-light);
  }

  :disabled {
    color: #696784;
  }

  .msts__control_deselect-all:disabled {
    &:after {
      content: "\276E";
      color: #696784;
    }
  }

  .msts__control_select-all:disabled {
    &:after {
      content: "\276F";
      color: #696784;
    }
  }

  .msts__control_deselect-all {
    &:after {
      content: "\276E";
      color: #6691ff;
    }
    &:disabled {
      color: #696784;
    }
    &:hover {
      color: #2d2f66;
    }
  }

  .msts__filter {
    position: relative;
  }

  .msts__filter-input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid silver;
    border-radius: 5px;
  }

  .msts__filter-clear {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 10px;
    font-size: 1.25em;
    color: silver;
    transition: color ease 0.15s;

    &:after {
      content: "\00D7";
      vertical-align: middle;
    }

    &:hover {
      color: #c0392b;
    }
  }
}

.msts_theme_example.msts_disabled {
  background-color: #ecf0f1;
}
