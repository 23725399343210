.subtitle-italic-right {
  float: right;
  font-style: italic;
  color: #aaa;
  font-size: 14px;
}

.one-on-one-card {
  margin-bottom: 2%;
  width: 100%;
}

.one-on-one-progressbar {
  overflow: hidden;
}

.one-on-one-progressbar p {
  margin: 0 0.25rem 0;
}

.one-on-one-card p {
  margin-bottom: 0;
}

.one-on-one-card .dropdown {
  float: right;
}

.one-on-one-card .ellipsis-btn {
  color: #000;
  background: none;
  border: none;
  padding: 0 0.5rem 0 1rem;
}

.one-on-one-card.ellipsis-btn::after {
  content: none;
}

.one-on-one-card .dropdown-item svg {
  margin-right: 0.5rem;
}

.one-on-one-card .card-body {
  justify-content: space-between;
  align-items: center;
}

.one-on-one-card .half-width .info-title {
  margin-bottom: 1rem;
  display: block;
}

.one-on-one-card .info-title {
  text-align: right;
  font-size: 14px;
  color: #aaa;
  margin: 0;
  text-align: center;
  margin-bottom: 0.5rem;
}

.one-on-one-card .step-progressbar {
  margin: 0.5rem 0;
}

.one-on-one-card .three-column-grid {
  text-align: center;
}

.one-on-one-card .three-column-grid > div:last-child p {
  margin-top: 0.25rem;
  line-height: 14px;
}

.one-on-one-card .circle-wrapper {
  width: 75px;
  margin: auto;
}

.one-on-one-card .card-title > span {
  font-size: 14px;
}

.one-on-one-card .card-footer {
  justify-content: space-between;
}

.one-on-one-card .card-footer .dropdown {
  margin: 0;
}

.footer-actions-wrapper .btn {
  margin-left: 0.5rem;
}
