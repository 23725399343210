div.modal-dialog.set-goals-modal {
  width: 90%;
}

.set-goals-modal div.modal-content div.modal-body img {
  width: 118px;
}

.set-goals-modal div.row.btn-row {
  margin-bottom: 9px;
}

div.row.btn-row button.btn-primary {
  margin-right: 26px;
}

.set-goals-modal-text p {
  margin-top: 3px;
  margin-bottom: 0;
}
