input.custom-date-picker {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

input.custom-date-picker:focus {
  color: #495057 !important;
  background-color: #ffffff !important;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.react-datepicker {
  background-color: #faf9f8;
  font-family: inherit;
}

.react-datepicker__header {
  text-align: center;
  background-color: #24274c;
  padding-top: 4px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #eeeeee;
  font-weight: 600;
  font-size: 0.944rem;
  cursor: default;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #eeeeee;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--primary-light);
}

.react-datepicker__navigation--next {
  border-left-color: #eeeeee;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #6691ff;
}

.react-datepicker__day-name {
  color: #eeeeee;
  cursor: default;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #464667;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range:hover {
  background-color: #24274c !important;
  color: #eeeeee !important;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__time-list-item--selected,
.react-datepicker__time-list--in-range:hover {
  background-color: #24274c !important;
  color: #eeeeee !important;
}

.react-datepicker__time-box {
  background-color: #f0f0f0;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  font-weight: bold;
}

.react-datepicker__day--today {
  background-color: var(--primary-light) !important;
  color: #eeeeee !important;
  border-radius: 0.3rem;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: #464667;
  color: #eeeeee;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  background-color: transparent !important;
  cursor: default !important;
  font-weight: normal !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
