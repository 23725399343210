svg.fa-trash-alt.fa-lg {
  margin-left: 11px;
  color: #403a34;
}

svg.fa-trash-alt.disabled {
  color: #7e7e7e;
}

.buttons-center {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.buttons-center button.btn-secondary {
  margin-left: 7px;
}
