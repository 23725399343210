.loading-container > .row > .col {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.loading-container > .loading-logo > .col {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loading-container > .loading-logo > .col > img {
  width: 300px;
}

.loading-container > .loading-text {
  padding-top: 1.5rem;
}

.loading-container > .loading-text > .col {
  color: var(--primary-light);
  font-size: 1.5rem;
  text-align: center;
}
