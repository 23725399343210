.navbar {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);
  -webkit-box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);
  -moz-box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);

  min-height: 75px;
  padding: 0 1rem 0 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar > .navbar-brand {
  cursor: pointer;
  padding: 0;
}

.navbar > .navbar-brand > img {
  width: auto;
  height: 25px;
}

.navbar-nav > .nav-link,
.navbar-nav > .dropdown.nav-item > .nav-link {
  color: var(--primary-blue) !important;
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0 0 0;
}

.navbar-nav > .nav-link::after,
.navbar-nav > .dropdown.nav-item::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0.25rem;
  left: 0;
}

.navbar-nav > .nav-link:hover::after,
.navbar-nav > .dropdown.nav-item:hover::after {
  width: 100%;
  background-color: darkgray;
}

.navbar-nav > .nav-link.selected::after,
.navbar-nav > .dropdown.nav-item.selected::after {
  width: 100%;
  background-color: var(--primary-light);
}

@media (min-width: 576px) {
  .navbar-nav > .dropdown.nav-item > .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-nav > .nav-link::after,
  .navbar-nav > .dropdown.nav-item::after {
    top: 3.125rem;
  }
}

.navbar > .dropdown.nav-item > .dropdown-toggle > svg,
.navbar > .dropdown.nav-item > .dropdown-toggle::after,
.navbar > .dropdown.nav-item > .dropdown-menu > .dropdown-item,
.navbar > .dropdown.nav-item > .dropdown-menu > .dropdown-item > svg,
.navbar .navbar-nav > .dropdown.nav-item > .dropdown-toggle::after,
.navbar .navbar-nav > .dropdown.nav-item > .dropdown-menu > .dropdown-item {
  height: 80;
  color: var(--primary-blue);
}

.navbar > .dropdown.nav-item:hover > .dropdown-toggle svg,
.navbar > .dropdown.nav-item:hover > .dropdown-toggle::after {
  color: var(--primary-dark-blue);
}

.navbar .navbar-nav > .dropdown.nav-item > .dropdown-menu,
.navbar > .dropdown.nav-item > .dropdown-menu {
  min-width: fit-content;
  font-size: 1rem;
}

.navbar > .dropdown.nav-item.user-profile .dropdown-menu {
  border: 0;
}
