.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2d2f66;
  border-color: #2d2f66;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--primary-light);
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.table-title a {
  color: #7d7c7c;
  font-weight: normal;
  text-decoration: none;
}

.table-title th {
  color: #7d7c7c;
  font-weight: normal;
}

.justify-content-md-center.audit {
  left: 0;
}
