.container.process-dashboard {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  margin-bottom: 10px !important;
}

.container.process-dashboard header button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.container.process-dashboard header button:first-child {
  margin-left: 0 !important;
}

.container.process-dashboard header button:last-child {
  margin-right: 0 !important;
}

.container.process-dashboard .process-progress {
  margin-top: 3% !important;
}

.container.process-dashboard .process-pie-charts {
  margin-top: 7% !important;
}

.container.process-dashboard .process-pie-charts .row.circle {
  background-color: #ffffff;
  border: 1px solid #000000;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px !important;
}

.container.process-dashboard .process-pie-charts .row.pie-legend {
  margin-top: 12px !important;
  font-size: 10.75px !important;
  justify-content: center !important;
  cursor: default !important;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col {
  height: 4px !important;
  cursor: pointer;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.danger {
  background: linear-gradient(
    to left,
    transparent 15%,
    #dd1273 0%,
    #dd1273 85%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.primary {
  background-color: #24274c;
  text-align: center;
}

.container.process-dashboard
  .process-pie-charts
  .row.pie-legend
  .col.primary.text-right {
  background-color: #24274c;
  background: linear-gradient(
    to left,
    transparent 10%,
    #24274c 0%,
    #24274c 80%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard
  .process-pie-charts
  .row.pie-legend
  .col.secondary {
  background: linear-gradient(
    to left,
    transparent 10%,
    #808285 0%,
    #808285 80%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.success {
  background: linear-gradient(
    to right,
    transparent 10%,
    var(--primary-light) 0%,
    var(--primary-light) 80%,
    transparent 0%
  );
  text-align: left;
}

.process-dashboard-toast {
  width: 30% !important;
}

.process-dashboard-toast div.toast-error {
  width: 100% !important;
  max-width: 100% !important;
}
