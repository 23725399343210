.copy-goals-container {
  margin: 0 0 2rem 0;
  overflow: hidden;
  padding: 0 0.1875rem 0 0.1875rem !important;
  width: 100%;
  min-height: fit-content !important;
}

.copy-goals-container > .row:first-child {
  height: 3rem;
}

.copy-goals-container > .row:first-child > div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.copy-goals-container > .row:first-child > .goals-title {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: flex-start;
  text-transform: uppercase;
}

.copy-goals-container > .table-header-row {
  border-bottom: 1px solid #c1c1c1;
}

.copy-goals-container > .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.copy-goals-container > .goals > .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  min-height: 8rem;
  margin-right: 0;
}

.copy-goals-container > .goals > .table-row > div {
  padding: 1rem;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) {
  align-items: center;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .row {
  margin-bottom: 0.3125rem;
}

.copy-goals-container
  > .goals
  > .table-row
  > div:nth-child(2)
  > .row:first-child {
  margin-bottom: 0.625rem;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.copy-goals-container
  > .goals
  > .table-row
  > div:nth-child(2)
  > .goal-title
  > .col {
  align-items: center;
  display: flex;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

.copy-goals-container > .goals > .table-row > .weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
}

.copy-goals-container .full-height {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: col;
  -ms-flex-direction: col;
  flex-direction: col;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: left;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.copy-goals-container .full-height-centered {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 50px;
}

.copy-goals-container .goals {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
