.one-on-one-comment-display-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0 1rem 0.25rem;
  padding: 2%;
}

.image-wrapper + div {
  display: flex;
  width: 100%;
}

.one-on-one-comment-display-wrapper .image-wrapper {
  margin-right: 1rem;
}

.one-on-one-comment-display-wrapper .image-wrapper svg,
.one-on-one-comment-display-wrapper .image-wrapper img {
  width: 50px;
}

.one-on-one-comment-display-wrapper .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #6691ff26;
  border: none;
}

.one-on-one-comment-display-wrapper .card::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #6691ff26;
  position: absolute;
  top: 15px;
  left: -10px;
}

.one-on-one-comment-display-wrapper .card .card-body {
  padding: 1rem;
}

.one-on-one-comment-display-wrapper .card .card-body .card-title {
  font-size: 1rem;
  font-weight: 600;
}
