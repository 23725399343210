.my-goals {
  width: 85%;
}

.goals-container {
  padding-left: 15px;
  padding-right: 15px;
}

.my-goals-table {
  width: 69%;
  float: right;
}

@media only screen and (min-width: 1680px) {
  .my-goals {
    width: 85%;
    padding-right: 0;
  }

  .goals-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 3000px) {
  .my-goals {
    width: 82%;
    padding-right: 0;
  }

  .my-goals-table {
    float: right;
  }
}
