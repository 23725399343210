@import url(https://fonts.googleapis.com/css?family=Crete+Round&display=swap);
.loading-container > .row > .col {
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.loading-container > .loading-logo > .col {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loading-container > .loading-logo > .col > img {
  width: 300px;
}

.loading-container > .loading-text {
  padding-top: 1.5rem;
}

.loading-container > .loading-text > .col {
  color: var(--primary-light);
  font-size: 1.5rem;
  text-align: center;
}

form.profile-picture-form .profile-picture {
  width: 300px;
  height: 300px;
}

form.profile-picture-form .row .col {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

form.profile-picture-form .row .col #upload-picture,
#remove-picture {
  margin-top: 1px;
}

form.profile-picture-form .row .col .space-between {
  width: 100px;
}

form.profile-picture-form #profile-picture-input {
  display: none !important;
}

.sidebar-right {
  background-color: #fff;
  position: fixed;
  top: 8%;
  right: 2px;
  width: 13rem;
  /*   height: 84%;
  float: right;
  padding-top: 75px; */
  float: right;
  z-index: -1;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid gray;
  margin-top: 6px;
}

@media only screen and (max-width: 1600px) {
  .sidebar-right {
    top: 10%;
  }
}

@media only screen and (max-width: 1400px) and (max-height: 850px) {
  .sidebar-right {
    top: 15%;
  }
}

.sidebar-right .row .col-md-6 figure.figure.user-profile-picture {
  display: inline-block;
  position: inherit;
}

.sidebar-right
  .row
  .col-md-6
  figure.figure.user-profile-picture
  .profile-image {
  width: 100%;
  height: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: 3%;
}

.sidebar-right .row .user-data {
  padding: 2rem 0 0 0;
}

.sidebar-right .row .user-data b.names {
  font-size: 1em;
}

.sidebar-right .row .user-data p {
  font-size: 1em;
  font-weight: normal;
}

.sidebar-right .row .user-data p.role-name {
  text-transform: capitalize;
}

.sidebar-right .row .user-data .buttons {
  margin-top: 40px;
}

.sidebar-right .row .user-data .buttons button.btn-link {
  padding: 0;
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);
  -webkit-box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);
  -moz-box-shadow: 0px 1px 5px 0px var(--primary-darkest-blue);

  min-height: 75px;
  padding: 0 1rem 0 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar > .navbar-brand {
  cursor: pointer;
  padding: 0;
}

.navbar > .navbar-brand > img {
  width: auto;
  height: 25px;
}

.navbar-nav > .nav-link,
.navbar-nav > .dropdown.nav-item > .nav-link {
  color: var(--primary-blue) !important;
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0 0 0;
}

.navbar-nav > .nav-link::after,
.navbar-nav > .dropdown.nav-item::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0.25rem;
  left: 0;
}

.navbar-nav > .nav-link:hover::after,
.navbar-nav > .dropdown.nav-item:hover::after {
  width: 100%;
  background-color: darkgray;
}

.navbar-nav > .nav-link.selected::after,
.navbar-nav > .dropdown.nav-item.selected::after {
  width: 100%;
  background-color: var(--primary-light);
}

@media (min-width: 576px) {
  .navbar-nav > .dropdown.nav-item > .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-nav > .nav-link::after,
  .navbar-nav > .dropdown.nav-item::after {
    top: 3.125rem;
  }
}

.navbar > .dropdown.nav-item > .dropdown-toggle > svg,
.navbar > .dropdown.nav-item > .dropdown-toggle::after,
.navbar > .dropdown.nav-item > .dropdown-menu > .dropdown-item,
.navbar > .dropdown.nav-item > .dropdown-menu > .dropdown-item > svg,
.navbar .navbar-nav > .dropdown.nav-item > .dropdown-toggle::after,
.navbar .navbar-nav > .dropdown.nav-item > .dropdown-menu > .dropdown-item {
  height: 80;
  color: var(--primary-blue);
}

.navbar > .dropdown.nav-item:hover > .dropdown-toggle svg,
.navbar > .dropdown.nav-item:hover > .dropdown-toggle::after {
  color: var(--primary-dark-blue);
}

.navbar .navbar-nav > .dropdown.nav-item > .dropdown-menu,
.navbar > .dropdown.nav-item > .dropdown-menu {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 1rem;
}

.navbar > .dropdown.nav-item.user-profile .dropdown-menu {
  border: 0;
}

table.employees {
  align-self: center;
}

table.employees > thead > tr,
table.employees > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.employees > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.employees > thead > tr > th:first-child,
table.employees > tbody > tr > td:first-child,
table.employees > tbody > tr > td:nth-child(2) {
  text-align: left;
}

table.employees > tbody > tr {
  background-color: #fff;
  height: 6.75rem;
}

table.employees > tbody > tr.inactive,
table.employees > tbody > tr.inactive > td > .btn-link {
  color: #c1c1c1;
}

table.employees > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: center;
}

table.employees > tbody > tr > td > .actions {
  position: absolute;
}

table.employees > tbody > tr > td > .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.employees > tbody > tr > td:nth-child(2) {
  width: 18rem;
}

table.employees > tbody > tr > td > .employee-name {
  font-size: 1rem;
  font-weight: bold;
}

table.employees > tbody > tr > td > .employee-position {
  font-size: 0.875rem;
}

table.employees > tbody > tr > td > .employee-email {
  font-size: 0.75rem;
  font-style: italic;
}

table.employees > tbody > tr > td > .appraisal-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.employees > tbody > tr > td > .main-action {
  align-items: center;
  display: flex;
  flex-direction: column;
}

table.employees > tbody > tr > td > .main-action > .btn {
  width: 50%;
}

table.employees > tbody > tr > td > .main-action > small {
  padding-top: 0.5rem;
}
.tooltip-inner {
  color: black;
  background: white;
  border-style: ridge;
}

.line-manager-label {
  width: 200px;
}
.personal-development-label {
  width: 400px;
}

.filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  /*  border-bottom: 1px solid #c1c1c1; */
}

.filter .btn-group .btn-outline-dark,
.filter .btn-group-toggle .btn-outline-dark,
.filter .btn-group .btn-outline-dark:focus,
.filter .btn-group-toggle .btn-outline-dark.active:focus,
.filter .btn-group .btn-outline-dark.active,
.filter .btn-group-toggle .btn-outline-dark.active,
.filter .btn-group .btn-outline-dark.active:focus,
.filter .btn-group-toggle .btn-outline-dark.active:focus {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: var(--primary-blue);
}

.filter .btn-group .btn-outline-dark.active,
.filter .btn-group-toggle .btn-outline-dark.active {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-light) !important;
  color: var(--primary-blue);
  font-weight: bold;
}

.filter .btn-group .btn-outline-dark:hover,
.filter .btn-group-toggle .btn-outline-dark:hover {
  background-color: transparent;
  border-bottom: 2px solid darkgray;
  color: var(--primary-blue);
}

.filter .filter-item {
  margin: 5px !important;
}

.filter input.filter-item,
.filter select.filter-item,
.filter .filter-item.input-group {
  width: 300px !important;
}

.filter .filter-item .btn {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.filter .btn.filter-reset {
  margin: 5px !important;
}

.filter fieldset.one-item-only {
  margin-left: 5px !important;
}

.filter fieldset {
  margin-left: 5px !important;
}

.filter fieldset legend {
  font-size: 1.25rem;
}

.filter fieldset .filter-item {
  min-width: 590px !important;
}

.filter input:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem transparent;
}

.filter .filter-item.input-group span#appendable {
  background-color: floralwhite;
}

.filter select,
.filter select.filter-item {
  color: #6c757d;
}

.filter select:focus,
.filter select.filter-item:focus {
  text-shadow: 0 0 0 #495057;
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem transparent;
}

.filter .btn-secondary {
  background-color: #ffffff;
  border-color: #ced4da;
  color: #6c757d;
}

.filter .btn-secondary:hover {
  background-color: var(--secondary-light-gray);
  border-color: #ced4da;
}

.filter .btn-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-blue);
}

.filter .btn-secondary:focus,
.filter .focus {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
  box-shadow: 0 0 0 0.2rem transparent;
  color: #ffffff;
}

.filter .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: var(--primary-blue);
}

.filter .badge-light {
  background-color: #e2e7ec;
}

.main-row {
  margin-top: 80px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  background-color: white;
}

.trainings-title {
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 25%;
  top: 2%;
}
.training-statuses {
  padding-left: 80px;
  padding-top: 530px;
  margin-bottom: 50px;
}
.trainings-content {
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.status-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.status-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.rectangle-col {
  text-align: center;
  font-size: 18px;
}

.months-col {
  border-left: solid;
  padding-top: 30px;
}

.months-title {
  text-align: center;
  font-weight: bold;
}
.training-status-rect {
  height: 80px;
  width: 130px;
  background-color: #bdd7ee;
  border-radius: 10px;
  margin-bottom: 20px;
}
.bar-column {
  border-left: solid;
}
.training-status-label {
  text-align: center;
  font-size: 18px;
}
.training-status-count {
  font-weight: bold;
}
.training-status-column {
  padding-top: 140px;
}
.doughnut-column {
  padding-top: 30px;
}
.status-container {
  display: flex;
}
.status-label {
  margin-right: 5px;
}
.percentage-value {
  font-weight: bold;
  display: "inline-block";
}
.doughnut-canvas {
  position: absolute;
  left: 45%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.pie-chart {
  margin-top: 80px;
}
.bar-chart {
  height: 600px;
}

.one-on-one-progressbar {
  display: flex;
  justify-content: center;
  --done-color: var(--primary-dark);
  --active-color: var(--primary-dark);
}

.one-on-one-progressbar p {
  margin: 0 2rem 0;
}

.one-on-one-progressbar .label {
  text-align: center;
}

.one-on-one-progressbar .label.top {
  color: #aaa;
  font-size: 14px;
  font-style: italic;
  height: 50px;
  min-width: 85px;
}

.one-on-one-progressbar .label.bottom {
  color: #aaa;
}

.one-on-one-progressbar .drawing {
  margin: auto;
  padding: 0.25rem 0 0.1rem;
  position: relative;
}

.one-on-one-progressbar .drawing::before {
  content: "";
  display: block;
  height: 2px;
  width: 101%;
  background-color: #ddd;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.one-on-one-progressbar .circle {
  --dimension: 20px;
  border-radius: 50%;
  background: #ddd;
  width: var(--dimension);
  height: var(--dimension);
  margin: auto;
}

.one-on-one-progressbar .step.done .label.bottom {
  color: var(--done-color);
}

.one-on-one-progressbar .step.done .circle {
  background: var(--done-color);
}

.one-on-one-progressbar .step.done .drawing::before {
  background-color: var(--done-color);
}

.one-on-one-progressbar .step.active .label.bottom {
  color: var(--active-color);
}

.one-on-one-progressbar .step.active .circle {
  background: var(--active-color);
}

.one-on-one-progressbar .step.active .drawing::before {
  background-color: var(--active-color);
}

.subtitle-italic-right {
  float: right;
  font-style: italic;
  color: #aaa;
  font-size: 14px;
}

.one-on-one-card {
  margin-bottom: 2%;
  width: 100%;
}

.one-on-one-progressbar {
  overflow: hidden;
}

.one-on-one-progressbar p {
  margin: 0 0.25rem 0;
}

.one-on-one-card p {
  margin-bottom: 0;
}

.one-on-one-card .dropdown {
  float: right;
}

.one-on-one-card .ellipsis-btn {
  color: #000;
  background: none;
  border: none;
  padding: 0 0.5rem 0 1rem;
}

.one-on-one-card.ellipsis-btn::after {
  content: none;
}

.one-on-one-card .dropdown-item svg {
  margin-right: 0.5rem;
}

.one-on-one-card .card-body {
  justify-content: space-between;
  align-items: center;
}

.one-on-one-card .half-width .info-title {
  margin-bottom: 1rem;
  display: block;
}

.one-on-one-card .info-title {
  text-align: right;
  font-size: 14px;
  color: #aaa;
  margin: 0;
  text-align: center;
  margin-bottom: 0.5rem;
}

.one-on-one-card .step-progressbar {
  margin: 0.5rem 0;
}

.one-on-one-card .three-column-grid {
  text-align: center;
}

.one-on-one-card .three-column-grid > div:last-child p {
  margin-top: 0.25rem;
  line-height: 14px;
}

.one-on-one-card .circle-wrapper {
  width: 75px;
  margin: auto;
}

.one-on-one-card .card-title > span {
  font-size: 14px;
}

.one-on-one-card .card-footer {
  justify-content: space-between;
}

.one-on-one-card .card-footer .dropdown {
  margin: 0;
}

.footer-actions-wrapper .btn {
  margin-left: 0.5rem;
}

.oneOnOne.filter {
  border: none;
  margin-left: 3%;
  margin-top: 2%;
}

.oneOnOneByRole.filter {
  border: none;
  margin-left: 3%;
  margin-top: 2%;
}

.setOneOnOne.btn.btn-primary {
  font-size: 1.1rem;
  justify-content: right;
}

.oneOnOne-label {
  padding-left: 2%;
}

@media only screen and (max-width: 1600px) {
  .setOneOnOne.btn.btn-primary {
    font-size: 0.95rem;
  }
}

.error-component-layout {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: #00234c;
}

.error-component-status-code {
  font-size: 12vmax;
  font-weight: lighter;
}

.error-component-heading {
  font-size: 3vmax;
  font-weight: normal;
}

.error-component-message {
  font-size: 2vmax;
  font-weight: normal;
}

.error-component-link,
.error-component-link:hover {
  color: #fff;
  text-decoration: none;
}

.goals-container {
  margin: 0 0 2rem 0;
  overflow: hidden;
  padding: 0 !important;
  width: 100%;
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.goals-container > .row:first-child {
  height: 3rem;
}

.goals-container > .row:first-child > div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.goals-container > .row:first-child > .goals-title {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: flex-start;
  text-transform: uppercase;
}

.goals-container > .row:first-child > div > .btn-link {
  padding: 0;
}

.goals-container > .table-header-row {
  border-bottom: 1px solid #c1c1c1;
}

.goals-container > .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.goals-container .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  font-size: 0.875rem;
  min-height: 8rem;
}

.goals-container .table-row > div {
  padding: 1rem 2rem 2.5rem 2rem;
}

.goals-container .table-row > div:first-child {
  align-items: center;
}

.goals-container .table-row > div:first-child > .row {
  margin-bottom: 0.3125rem;
}

.goals-container .table-row > div:first-child > .row:first-child {
  margin-bottom: 0.625rem;
}

.goals-container .table-row > div:first-child > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.goals-container .table-row > div:first-child > .goal-title > .col {
  align-items: center;
  display: flex;
}

.goals-container .table-row > div:first-child > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

.goals-container .table-row > div:first-child > .goal-setBy {
  font-size: 0.75rem;
  font-style: italic;
}

.goals-container .table-row > div:first-child > .goal-actions {
  font-size: 1rem;
  position: absolute;
  margin-bottom: 0;
}

.goals-container .table-row > div:first-child > .goal-actions .btn-link {
  padding: 0 1rem 0 0;
}

li .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
  padding: 0;
  vertical-align: middle;
}

li {
  list-style-type: none;
}

li .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding-bottom: 3px;
}

li .table-row > div {
  padding: 1rem 2rem 2.5rem 2rem;
}

li.table-row > div:first-child {
  align-items: center;
}

li .table-row > div:first-child > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

li .table-row > div:first-child > .goal-title > .col {
  align-items: center;
  display: flex;
}

li .table-row > div:first-child > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

li .table-row > div:first-child > .goal-setBy {
  font-size: 0.75rem;
  font-style: italic;
}

li.table-row > div:first-child > .goal-actions {
  font-size: 1rem;
  position: absolute;
  margin-bottom: 0;
}

li .table-row > div:first-child > .goal-actions .btn-link {
  padding: 0 1rem 0 0;
}

.goals-container .table-row.goal-inactive,
.goals-container .table-row.goal-inactive > div > .row,
.goals-container .table-row.goal-inactive > .weight-column {
  color: #c1c1c1;
}

.goals-container .full-height {
  display: flex;
  flex-direction: col;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.goals-container .full-height-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.my-goals {
  width: 85%;
}

.goals-container {
  padding-left: 15px;
  padding-right: 15px;
}

.my-goals-table {
  width: 69%;
  float: right;
}

@media only screen and (min-width: 1680px) {
  .my-goals {
    width: 85%;
    padding-right: 0;
  }

  .goals-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 3000px) {
  .my-goals {
    width: 82%;
    padding-right: 0;
  }

  .my-goals-table {
    float: right;
  }
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 2.75rem;
  font-weight: 700;
  fill: var(--primary-dark);
}

.circle-overlay {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.grade-selector .dropdown {
  margin: auto;
}

.grade-selector .dropdown-menu.show {
  -webkit-transform: translate(-38%, 75px) !important;
          transform: translate(-38%, 75px) !important;
}

.grade-text {
  color: #000;
  cursor: pointer;
  margin: 0;
  text-align: center;
  position: relative;
}

.select-arrow::before {
  content: "";
  display: inline-block;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  position: absolute;
  left: -15px;
  top: 9px;
}

.manager-select-arrow::before {
  content: "";
  display: inline-block;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  position: absolute;
  left: 18px;
  top: 9px;
}

.grade-text.grayed-out {
  color: #c6c6c6;
}

.row-aligned {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.row-aligned p {
  margin-right: 5px;
  cursor: default;
}

.comment-display-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0 1rem 0.25rem;
}

.image-wrapper + div {
  display: flex;
  width: 100%;
}

.comment-display-wrapper .image-wrapper {
  margin-right: 1rem;
}

.comment-display-wrapper .image-wrapper svg,
.comment-display-wrapper .image-wrapper img {
  width: 50px;
}

.comment-display-wrapper .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #6691ff26;
  border: none;
}

.comment-display-wrapper .card::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #6691ff26;
  position: absolute;
  top: 15px;
  left: -10px;
}

.comment-display-wrapper .card .card-body {
  padding: 1rem;
}

.comment-display-wrapper .card .card-body .card-title {
  font-size: 1rem;
  font-weight: 600;
  white-space: pre-line;
}

.comment-display-wrapper .card .card-body .card-text {
  white-space: pre-line;
  text-align: justify;
}

.comment-display-wrapper .card .card-body .card-title .grade {
  font-weight: 700;
  color: var(--primary-dark);
}

.comment-display-wrapper
  .card
  .grade-selector
  .CircularProgressbar
  .CircularProgressbar-text {
  fill: #a6a6a6;
}

.comment-display-wrapper
  .card
  .grade-selector
  .CircularProgressbar
  .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.comment-display-wrapper
  .card
  .manager-grade-selector
  .CircularProgressbar
  .CircularProgressbar-text {
  fill: #a6a6a6;
}

.circle-overlay {
  width: 50%;
  height: 50%;
  cursor: pointer;
}

.circle-overlay:hover {
  background: var(--primary-light);
  border-radius: 48%;
}

.manager-grade-selector.active .circle-overlay:hover {
  background: none;
  border-radius: 0%;
}

.manager-grade-selector .dropdown-menu {
  min-width: 0;
}

.manager-grade-selector .dropdown-menu.show {
  -webkit-transform: translate(-9%, 75px) !important;
          transform: translate(-9%, 75px) !important;
}
.CircularProgressbar .CircularProgressbar-text {
  font-size: 2.75rem;
  font-weight: 700;
  fill: var(--primary-dark);
}

.row-aligned {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 2rem;
}

.row-aligned p {
  margin-right: 5px;
  cursor: default;
}

.appraisal-preview {
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.appraisal-preview .header {
  padding-bottom: 0.3125rem;
  border-bottom: 0.0625rem solid #c1c1c1;
}

.appraisal-preview .header > .goals-header {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}
.appraisal-preview .table-header {
  border-bottom: 1px solid #c1c1c1;
}

.appraisal-preview .header,
.appraisal-preview .goals-header {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

.gray-heading {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 200;
  text-align: center;
  text-transform: none;
  padding: 0;
}

.appraisal-preview-row {
  border-bottom: 0.0625rem solid #c1c1c1;
  min-height: 60px !important;
  font-size: 0.875rem;
}

.appraisal-preview-row h5 {
  font-weight: 600;
}

.appraisal-row:first-child {
  margin-top: 0;
}

.appraisal-row {
  border-bottom: none;
  margin-top: 1rem;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.appraisal-preview p.goal-desc {
  margin-bottom: 0.125rem;
  font-size: 0.875rem;
  color: #535556;
}

.appraisal-preview .content {
  background-color: #fff;
  padding: 0 0.5rem;
}

.appraisal-preview .goal-inactive {
  color: #c1c1c1;
}

.appraisal-preview .bordered {
  border-right: 1px solid #c1c1c1;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.appraisal-preview .read-only {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.appraisal-preview .full-height {
  display: flex;
  flex-direction: col;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.appraisal-preview .full-height-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.appraisal-preview textarea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.appraisal-preview .comment_pd {
  white-space: pre-line;
}

.appraisal-preview p.comment_pd {
  white-space: pre-line;
  margin: 15px 20px;
}

.appraisal-preview .appraisal-preview-row .weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
  padding: 0;
}

.appraisal-preview [active="false"],
.appraisal-preview [active="false"] .goal-desc,
.appraisal-preview [active="false"] .appraisal-preview-row .weight-column {
  color: #c1c1c1;
}

.appraisal-preview svg.fa-comment-alt.disabled {
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 7px;
}

.appraisal-preview .row div[data-id="tooltip"] {
  text-transform: none;
  font-weight: normal;
}

.appraisal-preview div.place-left.type-dark[data-id="tooltip"] {
  max-width: 60%;
  z-index: 1040;
}

.appraisal-preview .comment-row h6 {
  font-weight: 600;
}

.comment-row.review-comment {
  border-bottom: 0;
}

.appraisal-preview .actions {
  position: absolute;
}

.custom-checkbox > .form-check > .form-check-input {
  height: 30px;
  width: 30px;
  right: 15rem;
}
.custom-checkbox > .form-check > .form-check-label {
  text-align: center;
  font-size: 20px;
  color: grey;
}

.appraisal-manage-button-relative{
  z-index: 999;
  position: relative;
}

.copy-goals-container {
  margin: 0 0 2rem 0;
  overflow: hidden;
  padding: 0 0.1875rem 0 0.1875rem !important;
  width: 100%;
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.copy-goals-container > .row:first-child {
  height: 3rem;
}

.copy-goals-container > .row:first-child > div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.copy-goals-container > .row:first-child > .goals-title {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: flex-start;
  text-transform: uppercase;
}

.copy-goals-container > .table-header-row {
  border-bottom: 1px solid #c1c1c1;
}

.copy-goals-container > .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.copy-goals-container > .goals > .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  min-height: 8rem;
  margin-right: 0;
}

.copy-goals-container > .goals > .table-row > div {
  padding: 1rem;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) {
  align-items: center;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .row {
  margin-bottom: 0.3125rem;
}

.copy-goals-container
  > .goals
  > .table-row
  > div:nth-child(2)
  > .row:first-child {
  margin-bottom: 0.625rem;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.copy-goals-container
  > .goals
  > .table-row
  > div:nth-child(2)
  > .goal-title
  > .col {
  align-items: center;
  display: flex;
}

.copy-goals-container > .goals > .table-row > div:nth-child(2) > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

.copy-goals-container > .goals > .table-row > .weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
}

.copy-goals-container .full-height {
  display: flex;
  flex-direction: col;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.copy-goals-container .full-height-centered {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.pagination {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 50px;
}

.copy-goals-container .goals {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

svg.icon {
  width: 15px;
  height: 15px;
  fill: var(--primary-light);
  padding-right: 1.8px;
}
svg.icon:hover {
  fill: var(--primary-dark);
}

div.modal-dialog.set-goals-modal {
  width: 90%;
}

.set-goals-modal div.modal-content div.modal-body img {
  width: 118px;
}

.set-goals-modal div.row.btn-row {
  margin-bottom: 9px;
}

div.row.btn-row button.btn-primary {
  margin-right: 26px;
}

.set-goals-modal-text p {
  margin-top: 3px;
  margin-bottom: 0;
}

.timeline-layout {
  padding-top: 250px;
}

.timeline-layout svg.fa-cog {
  color: var(--primary-light);
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
}

.figure {
  position: absolute;
  bottom: 1px;
}

.timeline-item-image {
  position: relative;
  width: 50px;
  height: 50px;
}

.timeline-item-image.impersonated {
  border-style: dotted;
  border-color: var(--alternative-pinky-red);
}

.timeline-item {
  position: relative;
  background-color: var(--primary-light);
  color: #ffffff;
  font-size: smaller;
  font-style: italic;
  font-weight: lighter;
  border-radius: 10px;
  padding: 8px;
}

.timeline-item.bigger {
  width: 180px !important;
}

.timeline-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 85%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--primary-light);
  border-top: 10.4px solid var(--primary-light);
  border-bottom: 10px solid transparent;
  margin-left: -20px;
  margin-bottom: -20px;
}

table.team-employees {
  align-self: center;
}

table.team-employees > thead > tr,
table.team-employees > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.team-employees > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.team-employees > thead > tr > th:first-child,
table.team-employees > tbody > tr > td:first-child,
table.team-employees > tbody > tr > td:nth-child(2) {
  text-align: left;
}

table.team-employees > tbody > tr {
  background-color: #fff;
  height: 6.75rem;
}

table.team-employees > tbody > tr.inactive,
table.team-employees > tbody > tr.inactive > td > .btn-link {
  color: #c1c1c1;
}

table.team-employees > tbody > tr > td {
  text-align: center;
}

table.team-employees > tbody > tr > td > .actions {
  position: absolute;
}

table.team-employees > tbody > tr > td > .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.team-employees > tbody > tr > td:nth-child(2) {
  width: 18rem;
}

table.team-employees > tbody > tr > td > .employee-name {
  font-size: 1rem;
  font-weight: bold;
}

table.team-employees > tbody > tr > td > .employee-position {
  font-size: 0.875rem;
}

table.team-employees > tbody > tr > td > .employee-email {
  font-size: 0.75rem;
  font-style: italic;
}

table.team-employees > tbody > tr > td > .appraisal-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.team-employees > tbody > tr > td > .main-action {
  align-items: center;
  display: flex;
  flex-direction: column;
}

table.team-employees > tbody > tr > td > .main-action > .btn {
  width: 50%;
}

table.team-employees > tbody > tr > td > .main-action > small {
  padding-top: 0.5rem;
}

/* table.appraisals {
  align-self: center;
  width: 70%;
} */

table.appraisals thead tr,
table.appraisals tbody tr {
  border-bottom: 1px solid #c1c1c1;
}

table.appraisals thead tr th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.appraisals thead tr th:first-child,
table.appraisals tbody tr td:first-child {
  width: 68%;
  text-align: left;
}

table.appraisals tbody tr {
  background-color: #fff;
  height: 6.5rem;
}

table.appraisals tbody tr:hover {
  background-color: #6691ff26;
  cursor: pointer;
}

table.appraisals tbody tr.appraisal-active:hover {
  background-color: #fff;
  cursor: default;
}

table.appraisals tbody tr td {
  padding: 0.25rem 1rem;
  text-align: center;
}

table.appraisals tbody tr td .appraisal-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.appraisals tbody tr td .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

table.appraisals tbody tr td .btn {
  margin: 1.75rem 0 0.5rem 0;
  width: 5rem;
}

table.appraisals tbody tr td .appraisal-pending {
  color: #7d7c7c;
  font-weight: 300;
}

table.appraisals tbody tr td .appraisal-score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

table.appraisals
  tbody
  tr
  td
  .appraisal-score
  .CircularProgressbar
  .CircularProgressbar-text {
  font-size: 2rem;
  font-weight: 500;
}

.profile-picture-column .profile-picture {
  height: 220px;
  width: 220px;
}

div.form-group.roles {
  margin-bottom: 10px !important;
}

div.roles-scrolable {
  border: 1px solid lightgrey;
  border-radius: 4px !important;
  -webkit-column-count: 3 !important;
          column-count: 3 !important;
  overflow-x: scroll !important;
  max-height: 100px !important;
  padding-left: 5px !important;
  width: 100% !important;
}

.file-upload-log .card .card-body .card-text {
  overflow-y: auto;
  max-height: 235px;
}

.file-upload-log .accordion .button {
  color: #808285;
}

.upload-file-modal {
  margin-top: 10%;
}

.upload-file-body {
  padding-bottom: 10;
}

.upload-file-body .row > .col > .dropzone {
  text-align: center;
  padding: 40px 0px 30px 0px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
}

.upload-file-btn {
  margin-top: 3%;
  margin-bottom: 10%;
}

.inactive {
  color: #c1c1c1;
}

a svg {
  color: #444444;
}

a svg:hover {
  color: #000000;
  cursor: pointer;
}

table.actionsTable {
  align-self: center;
}

table.actionsTable > thead > tr,
table.actionsTable > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.actionsTable > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.actionsTable > thead > tr > th:first-child,
table.actionsTable > tbody > tr > td:first-child,
table.actionsTable > tbody > tr > td:nth-child(2) {
  text-align: left;
}

table.actionsTable > tbody > tr {
  background-color: #fff;
  height: 6.75rem;
}

table.actionsTable > tbody > tr.inactive,
table.actionsTable > tbody > tr.inactive > td > .btn-link {
  color: #c1c1c1;
}

table.actionsTable > tbody > tr > td {
  padding: 0.25rem 1rem 1.5rem 1rem;
  text-align: center;
}

table.actionsTable > tbody > tr > td:nth-child(2) {
  width: 18rem;
}

table.actionsTable > tbody > tr > td > .employee-name {
  font-size: 1rem;
  font-weight: bold;
}

table.actionsTable > tbody > tr > td > .employee-position {
  font-size: 0.875rem;
}

table.actionsTable > tbody > tr > td > .employee-email {
  font-size: 0.75rem;
  font-style: italic;
}

table.actionsTable > tbody > tr > td > .appraisal-title,
table.actionsTable > tbody > tr > td > .review-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.actionsTable > tbody > tr > td > .actions {
  position: absolute;
}

table.actionsTable > tbody > tr > td > .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.actionsTable > tbody > tr > td > .actions > div:first-child > small {
  padding-top: 0.5rem;
}

table.actionsTable > tbody > tr > td > .actions svg {
  vertical-align: 0;
}

table.actionsTable > tbody > tr.change-on-hover {
  height: 6.5rem;
}

table.actionsTable > tbody > tr.change-on-hover > td {
  padding: 0.25rem 1rem;
}

table.actionsTable > tbody > tr.change-on-hover:hover {
  background-color: #6691ff26;
  cursor: pointer;
}

table.roles {
  align-self: center;
  width: 100%;
}

table.roles > thead > tr,
table.roles > tbody > tr {
  border-bottom: 1px solid #c1c1c1;
}

table.roles > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.3125rem 0.75rem;
  text-align: left;
}

table.roles > thead > tr > th:first-child,
table.roles > tbody > tr > td:first-child {
  width: 50%;
}

table.roles > thead > tr > th > .btn-plus {
  margin-left: 1rem;
  vertical-align: sub;
}

table.roles > tbody > tr {
  background-color: #fff;
  font-size: 1rem;
  height: 5rem;
}

.table.roles > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: left;
}

table.roles > tbody > tr > td > .actions {
  position: absolute;
}

table.roles > tbody > tr > td > .actions > .btn-link {
  font-size: 1rem;
  padding: 0 1rem 0 0;
}

.msts_theme_example {
  /* border: 1px solid silver; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .msts__heading {
    display: flex;

    .msts__side {
      padding: 5px;
      text-align: center;
      color: black;
      font-weight: bold;
    }

    .msts__side_available {
      color: #2d2f66;
      width: 50%;
    }

    .msts__side_selected {
      color: #2d2f66;
      width: 50%;
      float: right;
    }
  }

  .msts__subheading {
    display: flex;
    border-radius: 5px;

    .msts__side_filter {
      padding: 5px;
      width: 50%;
    }
  }

  .msts__body {
    margin-top: 1%;
    display: flex;

    .msts__side.msts__side_available {
      width: 45%;
      border: 1px solid silver;
      border-radius: 3px;
      margin-left: 1%;
    }

    .msts__side.msts__side_selected {
      width: 45%;
      border: 1px solid silver;
      border-radius: 3px;
      margin-right: 1%;
    }

    .msts__side.msts__side_controls {
      width: 10%;
      left: 3%;
      position: relative;
    }
  }

  .msts__footer {
    .msts__side {
      padding: 5px 15px;
      background-color: #ecf0f1;
      font-size: 0.75em;
      color: #7f8c8d;
      text-align: right;
    }
  }

  .msts__list {
    height: 199px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    list-style-type: none;
  }

  .msts__list-item {
    padding: 0;
    transition: background-color ease-in 0.1s, color ease-in 0.1s;

    &:hover {
      background-color: #6691ff;
      color: #fff;
    }

    &:disabled {
      background-color: #ecf0f1;
      color: #7f8c8d;

      &:hover {
        background-color: #ecf0f1;
        color: #7f8c8d;
      }
    }

    &_highlighted {
      background-color: #2d2f66;
    }
  }

  .msts__list-item:disabled {
    background-color: #ecf0f1;
    color: #7f8c8d;
  }

  .msts__list-item:disabled:hover {
    background-color: #ecf0f1;
    color: #7f8c8d;
  }

  .msts__control {
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px 3px;
    color: #bdc3c7;
    transition: color ease 0.15s;

    &:hover {
      color: #95a5a6;
    }

    &[disabled] {
      color: #696784;
    }
  }

  .msts_theme_example .msts__control:disabled {
    color: #696784;
  }

  .msts__control_select-all {
    &:after {
      content: "\276F";
      color: #6691ff;
    }
    &:hover {
      color: #2d2f66;
    }
  }

  .msts__control_select-all:disabled {
    color: #696784;
    pointer-events: none;
  }

  .msts__control_deselect-all:disabled {
    color: #696784;
    pointer-events: none;
  }

  .msts__control.msts__control_select-all:focus {
    outline: none;
  }

  .msts__control.msts__control_deselect-all:focus {
    outline: none;
  }

  .msts__filter-input:focus-visible {
    outline-color: var(--primary-light);
  }

  :disabled {
    color: #696784;
  }

  .msts__control_deselect-all:disabled {
    &:after {
      content: "\276E";
      color: #696784;
    }
  }

  .msts__control_select-all:disabled {
    &:after {
      content: "\276F";
      color: #696784;
    }
  }

  .msts__control_deselect-all {
    &:after {
      content: "\276E";
      color: #6691ff;
    }
    &:disabled {
      color: #696784;
    }
    &:hover {
      color: #2d2f66;
    }
  }

  .msts__filter {
    position: relative;
  }

  .msts__filter-input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid silver;
    border-radius: 5px;
  }

  .msts__filter-clear {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 10px;
    font-size: 1.25em;
    color: silver;
    transition: color ease 0.15s;

    &:after {
      content: "\00D7";
      vertical-align: middle;
    }

    &:hover {
      color: #c0392b;
    }
  }
}

.msts_theme_example.msts_disabled {
  background-color: #ecf0f1;
}

table.teams {
  align-self: center;
  width: 100%;
}

table.teams > thead > tr,
table.teams > tbody > tr {
  border-bottom: 1px solid #c1c1c1;
}

table.teams > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.3125rem 0.75rem;
  text-align: left;
}

table.teams > thead > tr > th:first-child,
table.teams > tbody > tr > td:first-child {
  width: 50%;
}

table.teams > thead > tr > th > .btn-plus {
  margin-left: 1rem;
  vertical-align: sub;
}

table.teams > tbody > tr {
  background-color: #fff;
  font-size: 1rem;
  height: 5rem;
}

.table.teams > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: left;
}

table.teams > tbody > tr > td > .actions {
  position: absolute;
}

table.teams > tbody > tr > td > .actions > .btn-link {
  font-size: 1rem;
  padding: 0 1rem 0 0;
}

.reset.btn-link.disabled,
.reset.btn-link:disabled {
  text-decoration: none;
}

table.processes {
  margin: auto;
}

table.processes > thead > tr,
table.processes > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.processes > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.processes > thead > tr > th:first-child,
table.processes > tbody > tr > td:first-child {
  text-align: left;
}

table.processes > thead > tr > th:last-child,
table.processes > tbody > tr > td:last-child {
  text-align: right;
}

table.processes > tbody > tr {
  background-color: #fff;
  height: 6.5rem;
}

table.processes > tbody > tr > td {
  padding: 0.75rem 1rem 1.875rem 1rem;
  text-align: center;
}

table.processes > tbody > tr > td:first-child {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

table.processes > tbody > tr > td > .process-completion {
  position: relative;
  top: 0;
  left: 0;
  width: 4.25rem;
  max-height: 4.25rem;
}

table.processes > tbody > tr > td > .process-completion > .CircularProgressbar {
  position: relative;
  top: 0;
  left: 0;
}

table.processes > tbody > tr > td > .process-completion > div {
  position: relative;
  top: -3.9rem;
  left: 0.625rem;
  width: 3rem;
}

table.processes > tbody > tr > td > .process-completion > div > div {
  font-size: 1rem;
  text-align: center;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  > div
  > .process-completed-participants {
  color: var(--primary-light);
  font-size: 1.2rem;
  font-weight: bold;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  > div
  > .process-participants {
  border-top: 1px solid silver;
  color: silver;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  .CircularProgressbar
  > .CircularProgressbar-path {
  stroke: var(--primary-light);
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  .CircularProgressbar
  > .CircularProgressbar-text {
  fill: var(--primary-light);
  font-size: 2rem;
}

table.processes > tbody > tr > td > .process-info {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 82.5%;
}

table.processes > tbody > tr > td > .process-info > .process-title > strong,
table.processes > tbody > tr > td > .process-days-left {
  font-size: 1rem;
}

table.processes > tbody > tr > td > .process-info > .process-title > span,
table.processes > tbody > tr > td > .process-info > .process-managed-by,
table.processes > tbody > tr > td > .process-period {
  font-size: 0.925rem;
}

table.processes > tbody > tr > td > .process-info > .process-managed-by {
  margin-top: 0.125rem;
}

table.processes > tbody > tr > td > .process-days-left {
  font-weight: bold;
  min-height: 1.5rem;
}

table.processes > tbody > tr > td > .process-days-left.green {
  color: green;
}

table.processes > tbody > tr > td > .process-days-left.yellow {
  color: gold;
}

table.processes > tbody > tr > td > .process-days-left.red {
  color: red;
}

table.processes
  > tbody
  > tr
  > td
  > .process-info
  > .process-managed-by
  > span:first-child,
table.processes > tbody > tr > td > .process-period {
  color: darkgray;
}

table.processes > tbody > tr > td .actions {
  position: absolute;
}

table.processes > tbody > tr > td:first-child .actions {
  margin: 3.75rem 0 0 5.55rem;
}

table.processes > tbody > tr > td:last-child .actions:lang(en) {
  margin: 0;
}

table.processes > tbody > tr > td:last-child .actions:lang(fr) {
  margin: 0;
}

table.processes > tbody > tr > td .actions:lang(bg) {
  margin-left: -3%;
  margin-top: 5px;
}

table.processes > tbody > tr > td .process.actions:lang(bg) {
  margin-left: 0;
  margin-top: 66px;
}

table.processes > tbody > tr > td .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.processes > tbody > tr > td .actions > .btn-link:last-child {
  padding: 0;
}

table.processes > tbody > tr > td .actions > .dropdown-toggle::after {
  content: none;
}

input.date-picker {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

input.date-picker:focus {
  color: #495057 !important;
  background-color: #ffffff !important;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.react-datepicker {
  background-color: #faf9f8;
  font-family: inherit;
}

.react-datepicker__header {
  text-align: center;
  background-color: #24274c;
  padding-top: 4px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #eeeeee;
  font-weight: 600;
  font-size: 0.944rem;
  cursor: default;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #eeeeee;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--primary-light);
}

.react-datepicker__navigation--next {
  border-left-color: #eeeeee;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #6691ff;
}

.react-datepicker__day-name {
  color: #eeeeee;
  cursor: default;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #464667;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range:hover {
  background-color: #24274c !important;
  color: #eeeeee !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  font-weight: bold;
}

.react-datepicker__day--today {
  background-color: var(--primary-light) !important;
  color: #eeeeee !important;
  border-radius: 0.3rem;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: #464667;
  color: #eeeeee;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  background-color: transparent !important;
  cursor: default !important;
  font-weight: normal !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.step-progressbar {
  display: flex;
  justify-content: center;
  --done-color: var(--primary-dark);
  --active-color: var(--primary-dark);
}

.step-progressbar p {
  margin: 0 2rem 0;
}

.step-progressbar .label {
  text-align: center;
}

.step-progressbar .label.top {
  color: #aaa;
  font-size: 14px;
  font-style: italic;
  height: 50px;
  min-width: 85px;
}

.step-progressbar .label.bottom {
  color: #aaa;
}

.step-progressbar .drawing {
  margin: auto;
  padding: 0.25rem 0 0.1rem;
  position: relative;
}

.step-progressbar .drawing::before {
  content: "";
  display: block;
  height: 2px;
  width: 101%;
  background-color: #ddd;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.step-progressbar .circle {
  --dimension: 20px;
  border-radius: 50%;
  background: #ddd;
  width: var(--dimension);
  height: var(--dimension);
  margin: auto;
}

.step-progressbar .step.done .label.bottom {
  color: var(--done-color);
}

.step-progressbar .step.done .circle {
  background: var(--done-color);
}

.step-progressbar .step.done .drawing::before {
  background-color: var(--done-color);
}

.step-progressbar .step.active .label.bottom {
  color: var(--active-color);
}

.step-progressbar .step.active .circle {
  background: var(--active-color);
}

.step-progressbar .step.active .drawing::before {
  background-color: var(--active-color);
}

.subtitle-italic-right {
  float: right;
  font-style: italic;
  color: #aaa;
  font-size: 14px;
}

.process-card .CircularProgressbar .CircularProgressbar-text {
  font-size: 1.625rem;
  font-weight: normal;
}

.process-card p {
  margin-bottom: 0;
}

.process-card .dropdown {
  float: right;
}

.process-card .ellipsis-btn {
  color: #000;
  background: none;
  border: none;
  padding: 0 0.5rem 0 1rem;
}

.process-card .ellipsis-btn::after {
  content: none;
}

.process-card .dropdown-item svg {
  margin-right: 0.5rem;
}

.process-card .card-body {
  justify-content: space-between;
  align-items: center;
}

.process-card .half-width .info-title {
  margin-bottom: 1rem;
  display: block;
}

.process-card .info-title {
  text-align: right;
  font-size: 14px;
  color: #aaa;
  margin: 0;
  text-align: center;
  margin-bottom: 0.5rem;
}

.process-card .step-progressbar {
  margin: 0.5rem 0;
}

.process-card .three-column-grid {
  text-align: center;
}

.process-card .three-column-grid > div:last-child p {
  margin-top: 0.25rem;
  line-height: 14px;
}

.process-card .circle-wrapper {
  width: 75px;
  margin: auto;
}

.process-card .card-title > span {
  font-size: 14px;
}

.process-card .card-footer {
  justify-content: space-between;
}

.process-card .card-footer .dropdown {
  margin: 0;
}

.footer-actions-wrapper .btn {
  margin-left: 0.5rem;
}

.processes-header a {
  font-size: 14px;
  position: relative;
  top: 3px;
  left: 0.5rem;
}

.animated .process-card {
  margin: 0.5rem 0;
}

table.process-table thead {
  background-color: #24274c;
  color: #eeeeee;
  text-transform: uppercase;
  border-bottom: none;
}

table.process-table thead th {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

table.process-table thead th svg.fa-plus-square {
  margin-left: 5px;
  color: #eeeeee;
}

table.process-table thead th div[data-id="tooltip"] {
  text-transform: none;
  font-weight: normal;
}

table.process-table.table-bordered tbody:nth-child(odd) {
  background-color: #f5f5f5;
}

table.process-table.table-bordered tbody td {
  border: 1px solid #c1c1c1;
  border-top: 0;
}

table.process-table.table-bordered tbody tr td:first-child {
  border-left: 0;
}

table.process-table.table-bordered tbody tr td:last-child {
  border-right: 0;
}

table.process-table tbody td {
  padding: 3px 5px 3px 5px;
}

table.process-table div.title {
  padding-left: 0;
}

table.process-table div.description {
  font-size: 14px;
}

.container.process-dashboard {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  margin-bottom: 10px !important;
}

.container.process-dashboard header button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.container.process-dashboard header button:first-child {
  margin-left: 0 !important;
}

.container.process-dashboard header button:last-child {
  margin-right: 0 !important;
}

.container.process-dashboard .process-progress {
  margin-top: 3% !important;
}

.container.process-dashboard .process-pie-charts {
  margin-top: 7% !important;
}

.container.process-dashboard .process-pie-charts .row.circle {
  background-color: #ffffff;
  border: 1px solid #000000;
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px !important;
}

.container.process-dashboard .process-pie-charts .row.pie-legend {
  margin-top: 12px !important;
  font-size: 10.75px !important;
  justify-content: center !important;
  cursor: default !important;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col {
  height: 4px !important;
  cursor: pointer;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.danger {
  background: linear-gradient(
    to left,
    transparent 15%,
    #dd1273 0%,
    #dd1273 85%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.primary {
  background-color: #24274c;
  text-align: center;
}

.container.process-dashboard
  .process-pie-charts
  .row.pie-legend
  .col.primary.text-right {
  background-color: #24274c;
  background: linear-gradient(
    to left,
    transparent 10%,
    #24274c 0%,
    #24274c 80%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard
  .process-pie-charts
  .row.pie-legend
  .col.secondary {
  background: linear-gradient(
    to left,
    transparent 10%,
    #808285 0%,
    #808285 80%,
    transparent 0%
  );
  text-align: right;
}

.container.process-dashboard .process-pie-charts .row.pie-legend .col.success {
  background: linear-gradient(
    to right,
    transparent 10%,
    var(--primary-light) 0%,
    var(--primary-light) 80%,
    transparent 0%
  );
  text-align: left;
}

.process-dashboard-toast {
  width: 30% !important;
}

.process-dashboard-toast div.toast-error {
  width: 100% !important;
  max-width: 100% !important;
}

.overview-table p.email {
  margin: 0;
  font-size: 14px;
  font-style: italic;
  color: #535556;
}

svg.fa-eye.disabled {
  color: #7e7e7e;
}

svg.fa-trash-alt.fa-lg {
  margin-left: 11px;
  color: #403a34;
}

svg.fa-trash-alt.disabled {
  color: #7e7e7e;
}

.buttons-center {
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.buttons-center button.btn-secondary {
  margin-left: 7px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2d2f66;
  border-color: #2d2f66;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--primary-light);
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.table-title a {
  color: #7d7c7c;
  font-weight: normal;
  text-decoration: none;
}

.table-title th {
  color: #7d7c7c;
  font-weight: normal;
}

.justify-content-md-center.audit {
  left: 0;
}

.post-final img {
  width: 200px;
}

.post-final h5 {
  margin: 0.625rem 0 0.625rem 0;
}

.post-final .right div.text {
  font-size: 1.125rem;
  padding-left: 1rem;
}

.post-final .mt-auto {
  margin-top: auto;
}

.post-final .mb-auto {
  margin-bottom: auto;
}

.post-final button.mr-auto {
  margin-right: auto;
  margin-left: 18px;
  width: 199px;
}

.post-final button.ml-auto {
  margin-left: auto;
  margin-right: 18px;
  width: 199px;
}

.post-final button p {
  margin-bottom: 0;
}

.post-final svg.fa-check {
  color: #109717;
}

.post-final svg.fa-question-circle {
  color: #0994d4;
}

.post-final svg.fa-exclamation-triangle {
  color: #ccaa3a;
}

.post-final .centered {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

table.template-groups {
  align-self: center;
  width: 100%;
}

table.template-groups > thead > tr,
table.template-groups > tbody > tr {
  border-bottom: 1px solid #c1c1c1;
}

table.template-groups > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.3125rem 0.75rem;
  text-align: center;
}

table.template-groups > thead > tr > th > .btn-plus {
  margin-left: 1rem;
  vertical-align: sub;
}

table.template-groups > thead > tr > th:first-child,
table.template-groups > tbody > tr > td:first-child {
  text-align: left;
}

table.template-groups > tbody > tr {
  background-color: #fff;
  font-size: 1rem;
  height: 5rem;
}

.table.template-groups > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: center;
}

table.template-groups > tbody > tr > td > .actions {
  position: absolute;
}

table.template-groups > tbody > tr > td > .actions > .btn-link {
  font-size: 1rem;
  padding: 0 1rem 0 0;
}

/* Colors used troughout the app */
:root {
  --primary-darkest-blue: #1b1c34;
  --primary-dark-blue: #202240;
  --primary-blue: #24274c;
  --primary-lightest-blue: #696784;
  --primary-dark: #2d2f66;
  --primary-light: #6691ff;
  --primary-blue-circle: #2950b3;
  --secondary-dark-blue: #2d2f66;
  --secondary-light-gray: #eeeeee;
  --alternative-pinky-red: #dd1273;
  --popover-light-yellow: #fffeca;
  --breadcrumb-text-color: #fff;
  --light-grey: #a6a6a6;
}

/* @font-face {
  font-family: "SpartanBold";
  src: url("./fonts/Spartan-Bold.ttf");
} */

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Regular.2d36b1a9.ttf);
}

body {
  background-color: #faf9f8;
  font-family: "Lato";
}

/* h1,
h2,
h3,
h4,
h5,
h6,
.modal-title {
  font-family: "SpartanBold";
} */

.badge.badge-pill.badge-primary {
  background-color: #1b1c34;
  background-color: var(--primary-darkest-blue);
}

.container {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3.125rem;
  min-height: 78vh;
}

.validation-error-msg {
  color: red;
  font-size: 12px;
  margin-top: 4%;
}

[class="custom-control-input"]:checked
  ~ [class="custom-control-label"]::before {
  background-color: #6691ff;
  background-color: var(--primary-light);
  border-color: #6691ff;
  border-color: var(--primary-light);
}

u.tooltip-dashed {
  border-bottom: 1px dashed #8a8a8a;
  text-decoration: none;
}

select.assessment {
  width: 190px;
}

table tbody tr td {
  vertical-align: middle !important;
}

table tbody tr td.profile-img {
  vertical-align: middle !important;
  width: 4.25rem;
  padding-right: 0;
}

table tbody tr td img {
  height: 50px;
}

.btn-primary {
  /* background-color: #236eaf; */
  /* border-color: #236eaf; */
  background-color: #6691ff;
  background-color: var(--primary-light);
  border-color: #6691ff;
  border-color: var(--primary-light);
}

.btn-primary:hover {
  /* background-color: #0257a1; */
  /* border-color: #0257a1; */
  background-color: #2d2f66;
  background-color: var(--primary-dark);
  border-color: #2d2f66;
  border-color: var(--primary-dark);
}

.btn-primary:focus {
  /* background-color: #0257a1 !important; */
  /* border-color: #0257a1 !important; */
  /* box-shadow: 0 0 0 0.2rem #236eaf8e !important; */
  background-color: #2d2f66 !important;
  background-color: var(--primary-dark) !important;
  border-color: #2d2f66 !important;
  border-color: var(--primary-dark) !important;
  box-shadow: 0 0 0 0.2rem #6691ff;
  box-shadow: 0 0 0 0.2rem var(--primary-light);
}

.btn-success {
  background-color: #046c61;
  border-color: #046c61;
}

.btn-success:hover {
  background-color: #045850;
  border-color: #045850;
}

.btn-success:focus {
  background-color: #045850 !important;
  border-color: #045850 !important;
  box-shadow: 0 0 0 0.2rem #046c618e !important;
}

.btn-outline-success {
  color: #046c61;
  border-color: #046c61;
}

.btn-outline-success:disabled {
  color: #046c61;
  border-color: #046c61;
}

.btn-outline-success:hover {
  background-color: #046c61;
  border-color: #046c61;
  color: #ffffff;
}

.btn-outline-success:focus {
  background-color: #046c61 !important;
  border-color: #046c61 !important;
  color: #ffffff;
  box-shadow: 0 0 0 0.2rem #046c618e !important;
}

.btn-danger {
  /* background-color: #a40080; */
  /* border-color: #a40080; */
  background-color: #dd1273;
  border-color: #dd1273;
}

.btn-danger:hover {
  /* background-color: #850068; */
  /* border-color: #850068; */
  background-color: #b00e5c;
  border-color: #b00e5c;
}

.btn-danger:focus {
  /* background-color: #850068 !important; */
  /* border-color: #850068 !important; */
  /* box-shadow: 0 0 0 0.2rem #a400808e !important; */
  background-color: #b00e5c;
  border-color: #b00e5c;
  box-shadow: 0 0 0 0.2rem #dd1273;
}

.btn-outline-danger {
  /* color: #a40080; */
  /* border-color: #a40080; */
  color: #dd1273;
  border-color: #dd1273;
}

.btn-outline-danger:hover {
  /* background-color: #a40080; */
  /* border-color: #a40080; */
  background-color: #dd1273;
  border-color: #dd1273;
  color: #ffffff;
}

.btn-outline-danger:focus {
  /* background-color: #a40080 !important; */
  /* border-color: #a40080 !important; */
  /* box-shadow: 0 0 0 0.2rem #a400808e !important; */
  background-color: #dd1273 !important;
  border-color: #dd1273 !important;
  box-shadow: 0 0 0 0.2rem #b00e5c;
  color: #ffffff;
}

.btn-secondary {
  background-color: #808285;
  border-color: #808285;
}

.btn-secondary:hover {
  background-color: #6f7174;
  border-color: #6f7174;
}

.btn-secondary:focus {
  background-color: #6f7174;
  border-color: #6f7174;
  box-shadow: 0 0 0 0.2rem #8082858e;
}

.btn-outline-secondary {
  color: #808285;
  border-color: #808285;
}

.btn-outline-secondary:disabled {
  color: #808285;
  border-color: #808285;
}

.btn-outline-secondary:hover {
  background-color: #808285;
  color: #ffffff;
  border-color: #808285;
}

.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem #8082858e !important;
}

.btn-outline-primary {
  color: #6691ff;
  color: var(--primary-light);
  border-color: #6691ff;
  border-color: var(--primary-light);
}

.btn-outline-primary:disabled {
  color: #6691ff;
  color: var(--primary-light);
  border-color: #6691ff;
  border-color: var(--primary-light);
}

.btn-outline-primary:hover {
  background-color: #6691ff;
  background-color: var(--primary-light);
  color: #ffffff;
  border-color: #6691ff;
  border-color: var(--primary-light);
}

.btn-outline-primary:active {
  background-color: #6691ff !important;
  background-color: var(--primary-light) !important;
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.0625rem #6691ff !important;
  box-shadow: 0 0 0 0.0625rem var(--primary-light) !important;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #6691ff;
  background-color: var(--primary-light);
  border-color: #6691ff;
  border-color: var(--primary-light);
}

.btn-outline-info {
  color: #4baeed;
  border-color: #4baeed;
}

.btn-outline-info:hover {
  background-color: #4baeed;
  color: #ffffff;
  border-color: #4baeed;
}

.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem #4baeed8e !important;
}

.btn-outline-dark {
  border-color: #2d2f66;
  border-color: var(--secondary-dark-blue);
  color: #2d2f66;
  color: var(--secondary-dark-blue);
}

.btn-outline-dark:hover {
  background-color: #2d2f66;
  background-color: var(--secondary-dark-blue);
  border-color: #2d2f66;
  border-color: var(--secondary-dark-blue);
  color: #fff;
}

a,
.btn-link {
  background-color: inherit;
  color: #6691ff;
  color: var(--primary-light);
}

a:focus,
a:hover,
.btn-link:focus,
.btn-link:hover {
  color: #2d2f66;
  color: var(--primary-dark);
  text-decoration: none;
}

a:focus svg > path,
a:hover svg > path,
.btn-link:focus svg > path,
.btn-link:hover svg > path {
  fill: #2d2f66;
  fill: var(--primary-dark);
}

.btn-link.disabled,
.btn-link:disabled {
  text-decoration: line-through;
}

.sort {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #000000;
  font-weight: 700;
  cursor: pointer !important;
}

div.progress-bar {
  background-color: #6691ff !important;
  background-color: var(--primary-light) !important;
}

.iframe-aad {
  margin: 0 auto;
  z-index: 1000;
  top: 100px;
  position: relative;
  visibility: hidden;
}

.bs-popover-top > .arrow::after {
  border-top-color: #fffeca;
  border-top-color: var(--popover-light-yellow);
}

.bs-popover-right > .arrow::after {
  border-right-color: #fffeca;
  border-right-color: var(--popover-light-yellow);
}

.popover {
  background-color: #fffeca;
  background-color: var(--popover-light-yellow);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.17);
}

.flex-display-wrap {
  display: flex;
  flex-wrap: wrap;
}

.half-width {
  width: 50%;
}

.three-column-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

button.btn {
  border-radius: 20px;
}

a.btn {
  border-radius: 20px;
}

svg.fa-trash-alt {
  margin: 0 !important;
}

.text-area {
  resize: none;
  /*   overflow: hidden; */
  min-height: 30px;
  white-space: pre-line;
  text-align: justify;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
}
.container-relative {
  position: relative;
}

.toast-success {
    background-color: #090;
}

.toast-error {
    background-color: #BD362F;
}
.questionBox {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: white;
  color: white;
}

.comment-display-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0 1rem 0.25rem;
  border: none;
}

.image-wrapper + div {
  display: flex;
  width: 100%;
}

.comment-display-wrapper .image-wrapper {
  margin-right: 1rem;
}

.comment-display-wrapper .image-wrapper svg,
.comment-display-wrapper .image-wrapper img {
  width: 50px;
}

.comment-display-wrapper ::before {
  /*  content: ""; */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #6691ff26;
  position: absolute;
  top: 15px;
  left: -10px;
}

.comment-display-wrapper .card-body {
  padding: 1rem;
}

.comment-display-wrapper .card-body .card-title {
  font-size: 1rem;
  font-weight: 600;
}

.questionCard {
    /* background-color: #fff; */
    border-radius: 2%;
    margin-bottom: 3rem;
    padding-left: 10%;
    width:100%;
    padding:0;
    margin:0;
}
.manager-select {
  margin-top: 2%;
}

.form-label {
  margin: 6px;
}

.SendForgotPassEmail-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  /*  justify-content: center; */
  min-height: 48px;
  margin: 0;
}

.forgot-password.modal {
  overflow-y: hidden;
}

.forgotPass.modal-body {
  padding: 0;
  margin: 0;
}

.forgotPass.modal-footer .btn {
  font-size: 16px;
}

.forgotPass.container {
  min-height: 0;
  padding: 0;
  margin: 0;
}

.SendForgotPassEmail-form.authentication-form .col .authentication-error {
  text-align: center;
  /* background-color: #ff4d4d; */

  margin-bottom: 0;
}

.SendForgotPassEmail-form.authentication-form {
  margin-top: 2%;
}

.SendForgotPassEmail-form.authentication-form p {
  margin-left: 3%;
}

.SendForgotPassEmail-form .validation-error-msg {
  color: red;
  font-size: 12px;
  margin-top: 2%;
}

input.SendForgotPassEmail.form-control {
  border: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: lightgrey;
  color: black;
}

input.SendForgotPassEmail.form-control.error {
  border-bottom: 1px solid red;
}

input.SendForgotPassEmail.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

input.SendForgotPassEmail:-webkit-autofill,
input.SendForgotPassEmail:-webkit-autofill:hover,
input.SendForgotPassEmail:-webkit-autofill:focus,
input.SendForgotPassEmail:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-light-gray) inset !important;
  /*  -webkit-text-fill-color: white; */
}

input.SendForgotPassEmail :-webkit-autofill-selected {
  color: var(--secondary-light-gray);
}

.UserAuthenticationForm-form.authentication-form {
  width: 350px;
  margin-left: 6%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.UserAuthenticationForm.custom-control-label {
  color: white;
}

.UserAuthenticationForm-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

.UserAuthenticationForm-form.authentication-form .col .authentication-error {
  text-align: center;
  color: white;
  border: 1px white;
  margin-bottom: 0;
}

.UserAuthenticationForm-form .validation-error-msg {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 2%;
}

input.UserAuthenticationForm.form-control {
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  text-align: center;
  color: white;
}

input.UserAuthenticationForm.form-control::-webkit-input-placeholder {
  color: white;
}

input.UserAuthenticationForm.form-control:-ms-input-placeholder {
  color: white;
}

input.UserAuthenticationForm.form-control::placeholder {
  color: white;
}

input.UserAuthenticationForm.form-control.error {
  border-bottom: 1px solid red;
}

input.UserAuthenticationForm.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

[placeholder].UserAuthenticationForm:focus::-webkit-input-placeholder {
  opacity: 0;
}

input.UserAuthenticationForm:-webkit-autofill,
input.UserAuthenticationForm:-webkit-autofill:hover,
input.UserAuthenticationForm:-webkit-autofill:focus,
input.UserAuthenticationForm:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-light) inset !important;
  -webkit-text-fill-color: white;
}

input.UserAuthenticationForm :-webkit-autofill-selected {
  color: white;
}

@media only screen and (min-width: 200px) {
  .container-relative.container-fluid {
    border-right: 1px solid #8d989a;
  }
}

.Lobby-form.lobby-form {
  width: 207px;
  margin-left: 30%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.Lobby.btn.btn-link.lobby {
  vertical-align: baseline;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
}

.Lobby.btn.btn-primary.lobby {
  width: 160px;
  margin-left: -13px;
}

.Lobby-form.lobby-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

@media only screen and (min-width: 200px) {
  .container-relative.container-fluid {
    border-right: 1px solid #8d989a;
  }
}

.Password-form.authentication-form {
  width: 350px;
  margin-left: 6%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.Password.custom-control-label {
  color: white;
}

.Password-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

.Password-form.authentication-form .col .authentication-error {
  text-align: center;
  color: white;
  border: 1px white;
  margin-bottom: 0;
}

.Password-form .validation-error-msg {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 2%;
}

input.Password.form-control {
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  text-align: center;
  color: white;
}

input.Password.form-control::-webkit-input-placeholder {
  color: white;
}

input.Password.form-control:-ms-input-placeholder {
  color: white;
}

input.Password.form-control::placeholder {
  color: white;
}

input.Password.form-control.error {
  border-bottom: 1px solid red;
}

input.Password.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

[placeholder].Password:focus::-webkit-input-placeholder {
  opacity: 0;
}

input.Password:-webkit-autofill,
input.Password:-webkit-autofill:hover,
input.Password:-webkit-autofill:focus,
input.Password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-light) inset !important;
  -webkit-text-fill-color: white;
}

input.Password :-webkit-autofill-selected {
  color: white;
}

.row.data {
  /* position: fixed; */
  bottom: 0;
  margin: auto 1rem auto 1%;
  font-size: 0.75em;
  display: flex;
  flex-direction: row;
  width: 90%;
}

.row.data p {
  float: left;
  width: 89%;
  margin-top: 2%;
  color: grey;
}

.row.data a {
  float: right;
  width: 10%;
}

.controlled-tabs a.nav-item.nav-link {
  border: transparent;
  color: var(--primary-blue);
  margin-bottom: 0.5px;
}

.controlled-tabs a.nav-item.nav-link:hover,
.controlled-tabs a.nav-item.nav-link:focus,
.controlled-tabs a.nav-item.nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-light);
}

.controlled-tabs a.nav-item.nav-link.active {
  font-weight: 600;
}

.profile-img {
  vertical-align: middle;
  width: 6.25rem;
  height: 6.25rem;
  padding-right: 0;
}

.header-row {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 7rem;
  opacity: 1;
  color: rgb(52, 71, 103);
  background: linear-gradient(90deg, #171932, #485eaf);
  border-radius: 1rem;
  background-position: 50% center;
  overflow: hidden;
  width: auto;
}

.my-profile-card.card .list-group-item .col-md-4,
.my-profile-card.card .list-group-item .col-md-12 {
  font-size: 1.1rem;
  color: var(--primary-blue);
  font-weight: bold;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.my-profile-card {
  border-radius: 1rem;
  overflow: hidden;
}

.header-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
          backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  position: relative;
  margin-top: -64px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 16px;
}

.header-col {
  display: flex;
  flex-direction: column;
}

.header-title-row {
  display: flex;
  flex-direction: row;
}

.header-image {
  width: 30%;
  flex-shrink: 1;
  float: left;
}

.my-profile-col {
  width: 40%;
  float: left;
  flex-grow: 1;
}

.col.my-profile-left {
  flex-basis: 87%;
}

.col.my-profile-right {
  flex-basis: 37%;
}

.my-profile-tabs.controlled-tabs {
  height: 50%;
  right: 45%;
  position: relative;
}

@media only screen and (max-width: 1600px) {
  .header-card {
    display: flex;
    flex-direction: column;
  }

  .header-col {
    display: flex;
    /*  flex-direction: column; */
  }

  .header-col {
    display: flex;
    flex-direction: row;
  }

  .my-profile-col {
    width: 40%;
    float: left;
    flex-grow: 1;
  }

  .my-profile-left {
    flex-basis: 56%;
  }

  /* .col.my-profile-right {
    flex-basis: 33%;
  } */

  .header-title-row {
    width: 100%;
  }

  .my-profile-tabs.controlled-tabs {
    right: 36%;
  }
}

.my-profile-card .card-body {
  min-height: 1px;
  padding: 1.25rem;
  flex: 0 0 auto;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-avatar.user-pic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.container.home {
  /* margin-top: 2rem !important; */
  width: 66%;
  padding-top: 2.125rem;
}

.container.home .row .col h2,
.container.home .row .col h3 {
  margin-bottom: 20px;
}

.container.home p {
  margin-bottom: 15px !important;
}

.container.home .row .col .row.manager {
  margin-top: 30px;
}

.container.home .row .col .row.manager img {
  width: 200px !important;
}

.container.home .row .col .row.manager h3 {
  margin-bottom: 8px !important;
}

.container.home .row .col .row.manager h4 {
  margin-bottom: 30px !important;
  font-weight: 600 !important;
}

.container.home .row .col .row.manager .row.email .col span {
  position: absolute !important;
  top: 2px !important;
}

input.custom-date-picker {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

input.custom-date-picker:focus {
  color: #495057 !important;
  background-color: #ffffff !important;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.react-datepicker {
  background-color: #faf9f8;
  font-family: inherit;
}

.react-datepicker__header {
  text-align: center;
  background-color: #24274c;
  padding-top: 4px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #eeeeee;
  font-weight: 600;
  font-size: 0.944rem;
  cursor: default;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #eeeeee;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--primary-light);
}

.react-datepicker__navigation--next {
  border-left-color: #eeeeee;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #6691ff;
}

.react-datepicker__day-name {
  color: #eeeeee;
  cursor: default;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #464667;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range:hover {
  background-color: #24274c !important;
  color: #eeeeee !important;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__time-list-item--selected,
.react-datepicker__time-list--in-range:hover {
  background-color: #24274c !important;
  color: #eeeeee !important;
}

.react-datepicker__time-box {
  background-color: #f0f0f0;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  font-weight: bold;
}

.react-datepicker__day--today {
  background-color: var(--primary-light) !important;
  color: #eeeeee !important;
  border-radius: 0.3rem;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: #464667;
  color: #eeeeee;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  background-color: transparent !important;
  cursor: default !important;
  font-weight: normal !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.one-on-one-comment-display-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0 1rem 0.25rem;
  padding: 2%;
}

.image-wrapper + div {
  display: flex;
  width: 100%;
}

.one-on-one-comment-display-wrapper .image-wrapper {
  margin-right: 1rem;
}

.one-on-one-comment-display-wrapper .image-wrapper svg,
.one-on-one-comment-display-wrapper .image-wrapper img {
  width: 50px;
}

.one-on-one-comment-display-wrapper .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #6691ff26;
  border: none;
}

.one-on-one-comment-display-wrapper .card::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #6691ff26;
  position: absolute;
  top: 15px;
  left: -10px;
}

.one-on-one-comment-display-wrapper .card .card-body {
  padding: 1rem;
}

.one-on-one-comment-display-wrapper .card .card-body .card-title {
  font-size: 1rem;
  font-weight: 600;
}

