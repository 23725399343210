.step-progressbar {
  display: flex;
  justify-content: center;
  --done-color: var(--primary-dark);
  --active-color: var(--primary-dark);
}

.step-progressbar p {
  margin: 0 2rem 0;
}

.step-progressbar .label {
  text-align: center;
}

.step-progressbar .label.top {
  color: #aaa;
  font-size: 14px;
  font-style: italic;
  height: 50px;
  min-width: 85px;
}

.step-progressbar .label.bottom {
  color: #aaa;
}

.step-progressbar .drawing {
  margin: auto;
  padding: 0.25rem 0 0.1rem;
  position: relative;
}

.step-progressbar .drawing::before {
  content: "";
  display: block;
  height: 2px;
  width: 101%;
  background-color: #ddd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.step-progressbar .circle {
  --dimension: 20px;
  border-radius: 50%;
  background: #ddd;
  width: var(--dimension);
  height: var(--dimension);
  margin: auto;
}

.step-progressbar .step.done .label.bottom {
  color: var(--done-color);
}

.step-progressbar .step.done .circle {
  background: var(--done-color);
}

.step-progressbar .step.done .drawing::before {
  background-color: var(--done-color);
}

.step-progressbar .step.active .label.bottom {
  color: var(--active-color);
}

.step-progressbar .step.active .circle {
  background: var(--active-color);
}

.step-progressbar .step.active .drawing::before {
  background-color: var(--active-color);
}
