.goals-container {
  margin: 0 0 2rem 0;
  overflow: hidden;
  padding: 0 !important;
  width: 100%;
  min-height: fit-content !important;
}

.goals-container > .row:first-child {
  height: 3rem;
}

.goals-container > .row:first-child > div {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.goals-container > .row:first-child > .goals-title {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: flex-start;
  text-transform: uppercase;
}

.goals-container > .row:first-child > div > .btn-link {
  padding: 0;
}

.goals-container > .table-header-row {
  border-bottom: 1px solid #c1c1c1;
}

.goals-container > .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.goals-container .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  font-size: 0.875rem;
  min-height: 8rem;
}

.goals-container .table-row > div {
  padding: 1rem 2rem 2.5rem 2rem;
}

.goals-container .table-row > div:first-child {
  align-items: center;
}

.goals-container .table-row > div:first-child > .row {
  margin-bottom: 0.3125rem;
}

.goals-container .table-row > div:first-child > .row:first-child {
  margin-bottom: 0.625rem;
}

.goals-container .table-row > div:first-child > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.goals-container .table-row > div:first-child > .goal-title > .col {
  align-items: center;
  display: flex;
}

.goals-container .table-row > div:first-child > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

.goals-container .table-row > div:first-child > .goal-setBy {
  font-size: 0.75rem;
  font-style: italic;
}

.goals-container .table-row > div:first-child > .goal-actions {
  font-size: 1rem;
  position: absolute;
  margin-bottom: 0;
}

.goals-container .table-row > div:first-child > .goal-actions .btn-link {
  padding: 0 1rem 0 0;
}

li .table-header-row > .col {
  align-items: center;
  color: #7d7c7c;
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  font-weight: normal;
  justify-content: flex-end;
}

.weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
  padding: 0;
  vertical-align: middle;
}

li {
  list-style-type: none;
}

li .table-row {
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding-bottom: 3px;
}

li .table-row > div {
  padding: 1rem 2rem 2.5rem 2rem;
}

li.table-row > div:first-child {
  align-items: center;
}

li .table-row > div:first-child > .goal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

li .table-row > div:first-child > .goal-title > .col {
  align-items: center;
  display: flex;
}

li .table-row > div:first-child > .goal-desc {
  font-size: 0.875rem;
  color: #535556;
}

li .table-row > div:first-child > .goal-setBy {
  font-size: 0.75rem;
  font-style: italic;
}

li.table-row > div:first-child > .goal-actions {
  font-size: 1rem;
  position: absolute;
  margin-bottom: 0;
}

li .table-row > div:first-child > .goal-actions .btn-link {
  padding: 0 1rem 0 0;
}

.goals-container .table-row.goal-inactive,
.goals-container .table-row.goal-inactive > div > .row,
.goals-container .table-row.goal-inactive > .weight-column {
  color: #c1c1c1;
}

.goals-container .full-height {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: col;
  -ms-flex-direction: col;
  flex-direction: col;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: left;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.goals-container .full-height-centered {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
