.timeline-layout {
  padding-top: 250px;
}

.timeline-layout svg.fa-cog {
  color: var(--primary-light);
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
}

.figure {
  position: absolute;
  bottom: 1px;
}

.timeline-item-image {
  position: relative;
  width: 50px;
  height: 50px;
}

.timeline-item-image.impersonated {
  border-style: dotted;
  border-color: var(--alternative-pinky-red);
}

.timeline-item {
  position: relative;
  background-color: var(--primary-light);
  color: #ffffff;
  font-size: smaller;
  font-style: italic;
  font-weight: lighter;
  border-radius: 10px;
  padding: 8px;
}

.timeline-item.bigger {
  width: 180px !important;
}

.timeline-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 85%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--primary-light);
  border-top: 10.4px solid var(--primary-light);
  border-bottom: 10px solid transparent;
  margin-left: -20px;
  margin-bottom: -20px;
}
