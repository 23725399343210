.Password-form.authentication-form {
  width: 350px;
  margin-left: 6%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.Password.custom-control-label {
  color: white;
}

.Password-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

.Password-form.authentication-form .col .authentication-error {
  text-align: center;
  color: white;
  border: 1px white;
  margin-bottom: 0;
}

.Password-form .validation-error-msg {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 2%;
}

input.Password.form-control {
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  text-align: center;
  color: white;
}

input.Password.form-control::placeholder {
  color: white;
}

input.Password.form-control.error {
  border-bottom: 1px solid red;
}

input.Password.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

[placeholder].Password:focus::-webkit-input-placeholder {
  opacity: 0;
}

input.Password:-webkit-autofill,
input.Password:-webkit-autofill:hover,
input.Password:-webkit-autofill:focus,
input.Password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-light) inset !important;
  -webkit-text-fill-color: white;
}

input.Password :-webkit-autofill-selected {
  color: white;
}
