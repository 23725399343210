/* table.appraisals {
  align-self: center;
  width: 70%;
} */

table.appraisals thead tr,
table.appraisals tbody tr {
  border-bottom: 1px solid #c1c1c1;
}

table.appraisals thead tr th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.appraisals thead tr th:first-child,
table.appraisals tbody tr td:first-child {
  width: 68%;
  text-align: left;
}

table.appraisals tbody tr {
  background-color: #fff;
  height: 6.5rem;
}

table.appraisals tbody tr:hover {
  background-color: #6691ff26;
  cursor: pointer;
}

table.appraisals tbody tr.appraisal-active:hover {
  background-color: #fff;
  cursor: default;
}

table.appraisals tbody tr td {
  padding: 0.25rem 1rem;
  text-align: center;
}

table.appraisals tbody tr td .appraisal-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.appraisals tbody tr td .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

table.appraisals tbody tr td .btn {
  margin: 1.75rem 0 0.5rem 0;
  width: 5rem;
}

table.appraisals tbody tr td .appraisal-pending {
  color: #7d7c7c;
  font-weight: 300;
}

table.appraisals tbody tr td .appraisal-score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

table.appraisals
  tbody
  tr
  td
  .appraisal-score
  .CircularProgressbar
  .CircularProgressbar-text {
  font-size: 2rem;
  font-weight: 500;
}
