.profile-picture-column .profile-picture {
  height: 220px;
  width: 220px;
}

div.form-group.roles {
  margin-bottom: 10px !important;
}

div.roles-scrolable {
  border: 1px solid lightgrey;
  border-radius: 4px !important;
  column-count: 3 !important;
  overflow-x: scroll !important;
  max-height: 100px !important;
  padding-left: 5px !important;
  width: 100% !important;
}
