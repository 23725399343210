.container.home {
  /* margin-top: 2rem !important; */
  width: 66%;
  padding-top: 2.125rem;
}

.container.home .row .col h2,
.container.home .row .col h3 {
  margin-bottom: 20px;
}

.container.home p {
  margin-bottom: 15px !important;
}

.container.home .row .col .row.manager {
  margin-top: 30px;
}

.container.home .row .col .row.manager img {
  width: 200px !important;
}

.container.home .row .col .row.manager h3 {
  margin-bottom: 8px !important;
}

.container.home .row .col .row.manager h4 {
  margin-bottom: 30px !important;
  font-weight: 600 !important;
}

.container.home .row .col .row.manager .row.email .col span {
  position: absolute !important;
  top: 2px !important;
}
