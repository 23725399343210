.error-component-layout {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: #00234c;
}

.error-component-status-code {
  font-size: 12vmax;
  font-weight: lighter;
}

.error-component-heading {
  font-size: 3vmax;
  font-weight: normal;
}

.error-component-message {
  font-size: 2vmax;
  font-weight: normal;
}

.error-component-link,
.error-component-link:hover {
  color: #fff;
  text-decoration: none;
}
