.Lobby-form.lobby-form {
  width: 207px;
  margin-left: 30%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.Lobby.btn.btn-link.lobby {
  vertical-align: baseline;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
}

.Lobby.btn.btn-primary.lobby {
  width: 160px;
  margin-left: -13px;
}

.Lobby-form.lobby-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

@media only screen and (min-width: 200px) {
  .container-relative.container-fluid {
    border-right: 1px solid #8d989a;
  }
}
