.processes-header a {
  font-size: 14px;
  position: relative;
  top: 3px;
  left: 0.5rem;
}

.animated .process-card {
  margin: 0.5rem 0;
}

table.process-table thead {
  background-color: #24274c;
  color: #eeeeee;
  text-transform: uppercase;
  border-bottom: none;
}

table.process-table thead th {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

table.process-table thead th svg.fa-plus-square {
  margin-left: 5px;
  color: #eeeeee;
}

table.process-table thead th div[data-id="tooltip"] {
  text-transform: none;
  font-weight: normal;
}

table.process-table.table-bordered tbody:nth-child(odd) {
  background-color: #f5f5f5;
}

table.process-table.table-bordered tbody td {
  border: 1px solid #c1c1c1;
  border-top: 0;
}

table.process-table.table-bordered tbody tr td:first-child {
  border-left: 0;
}

table.process-table.table-bordered tbody tr td:last-child {
  border-right: 0;
}

table.process-table tbody td {
  padding: 3px 5px 3px 5px;
}

table.process-table div.title {
  padding-left: 0;
}

table.process-table div.description {
  font-size: 14px;
}
