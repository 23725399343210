table.employees {
  align-self: center;
}

table.employees > thead > tr,
table.employees > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.employees > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.employees > thead > tr > th:first-child,
table.employees > tbody > tr > td:first-child,
table.employees > tbody > tr > td:nth-child(2) {
  text-align: left;
}

table.employees > tbody > tr {
  background-color: #fff;
  height: 6.75rem;
}

table.employees > tbody > tr.inactive,
table.employees > tbody > tr.inactive > td > .btn-link {
  color: #c1c1c1;
}

table.employees > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: center;
}

table.employees > tbody > tr > td > .actions {
  position: absolute;
}

table.employees > tbody > tr > td > .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.employees > tbody > tr > td:nth-child(2) {
  width: 18rem;
}

table.employees > tbody > tr > td > .employee-name {
  font-size: 1rem;
  font-weight: bold;
}

table.employees > tbody > tr > td > .employee-position {
  font-size: 0.875rem;
}

table.employees > tbody > tr > td > .employee-email {
  font-size: 0.75rem;
  font-style: italic;
}

table.employees > tbody > tr > td > .appraisal-title {
  font-size: 1.05rem;
  font-weight: 600;
}

table.employees > tbody > tr > td > .main-action {
  align-items: center;
  display: flex;
  flex-direction: column;
}

table.employees > tbody > tr > td > .main-action > .btn {
  width: 50%;
}

table.employees > tbody > tr > td > .main-action > small {
  padding-top: 0.5rem;
}
.tooltip-inner {
  color: black;
  background: white;
  border-style: ridge;
}
