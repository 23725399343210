.UserAuthenticationForm-form.authentication-form {
  width: 350px;
  margin-left: 6%;
}

body.login {
  background: linear-gradient(
    90deg,
    rgba(23, 25, 50, 1) 0%,
    rgba(72, 94, 175, 1) 100%
  );
}

.UserAuthenticationForm.custom-control-label {
  color: white;
}

.UserAuthenticationForm-form.authentication-form .col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 48px;
  margin: 0;
}

.UserAuthenticationForm-form.authentication-form .col .authentication-error {
  text-align: center;
  color: white;
  border: 1px white;
  margin-bottom: 0;
}

.UserAuthenticationForm-form .validation-error-msg {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 2%;
}

input.UserAuthenticationForm.form-control {
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid grey;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  text-align: center;
  color: white;
}

input.UserAuthenticationForm.form-control::placeholder {
  color: white;
}

input.UserAuthenticationForm.form-control.error {
  border-bottom: 1px solid red;
}

input.UserAuthenticationForm.form-control.notAuthenticated {
  border-bottom: 1px solid red;
}

[placeholder].UserAuthenticationForm:focus::-webkit-input-placeholder {
  opacity: 0;
}

input.UserAuthenticationForm:-webkit-autofill,
input.UserAuthenticationForm:-webkit-autofill:hover,
input.UserAuthenticationForm:-webkit-autofill:focus,
input.UserAuthenticationForm:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-light) inset !important;
  -webkit-text-fill-color: white;
}

input.UserAuthenticationForm :-webkit-autofill-selected {
  color: white;
}

@media only screen and (min-width: 200px) {
  .container-relative.container-fluid {
    border-right: 1px solid #8d989a;
  }
}
