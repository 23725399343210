.filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  /*  border-bottom: 1px solid #c1c1c1; */
}

.filter .btn-group .btn-outline-dark,
.filter .btn-group-toggle .btn-outline-dark,
.filter .btn-group .btn-outline-dark:focus,
.filter .btn-group-toggle .btn-outline-dark.active:focus,
.filter .btn-group .btn-outline-dark.active,
.filter .btn-group-toggle .btn-outline-dark.active,
.filter .btn-group .btn-outline-dark.active:focus,
.filter .btn-group-toggle .btn-outline-dark.active:focus {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: var(--primary-blue);
}

.filter .btn-group .btn-outline-dark.active,
.filter .btn-group-toggle .btn-outline-dark.active {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-light) !important;
  color: var(--primary-blue);
  font-weight: bold;
}

.filter .btn-group .btn-outline-dark:hover,
.filter .btn-group-toggle .btn-outline-dark:hover {
  background-color: transparent;
  border-bottom: 2px solid darkgray;
  color: var(--primary-blue);
}

.filter .filter-item {
  margin: 5px !important;
}

.filter input.filter-item,
.filter select.filter-item,
.filter .filter-item.input-group {
  width: 300px !important;
}

.filter .filter-item .btn {
  width: fit-content !important;
}

.filter .btn.filter-reset {
  margin: 5px !important;
}

.filter fieldset.one-item-only {
  margin-left: 5px !important;
}

.filter fieldset {
  margin-left: 5px !important;
}

.filter fieldset legend {
  font-size: 1.25rem;
}

.filter fieldset .filter-item {
  min-width: 590px !important;
}

.filter input:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem transparent;
}

.filter .filter-item.input-group span#appendable {
  background-color: floralwhite;
}

.filter select,
.filter select.filter-item {
  color: #6c757d;
}

.filter select:focus,
.filter select.filter-item:focus {
  text-shadow: 0 0 0 #495057;
  border-color: #ced4da;
  box-shadow: 0 0 0 0.2rem transparent;
}

.filter .btn-secondary {
  background-color: #ffffff;
  border-color: #ced4da;
  color: #6c757d;
}

.filter .btn-secondary:hover {
  background-color: var(--secondary-light-gray);
  border-color: #ced4da;
}

.filter .btn-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-blue);
}

.filter .btn-secondary:focus,
.filter .focus {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
  box-shadow: 0 0 0 0.2rem transparent;
  color: #ffffff;
}

.filter .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: var(--primary-blue);
}

.filter .badge-light {
  background-color: #e2e7ec;
}
