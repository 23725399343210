.upload-file-modal {
  margin-top: 10%;
}

.upload-file-body {
  padding-bottom: 10;
}

.upload-file-body .row > .col > .dropzone {
  text-align: center;
  padding: 40px 0px 30px 0px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
}

.upload-file-btn {
  margin-top: 3%;
  margin-bottom: 10%;
}
