.profile-img {
  vertical-align: middle;
  width: 6.25rem;
  height: 6.25rem;
  padding-right: 0;
}

.header-row {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  min-height: 7rem;
  opacity: 1;
  color: rgb(52, 71, 103);
  background: linear-gradient(90deg, #171932, #485eaf);
  border-radius: 1rem;
  background-position: 50% center;
  overflow: hidden;
  width: auto;
}

.my-profile-card.card .list-group-item .col-md-4,
.my-profile-card.card .list-group-item .col-md-12 {
  font-size: 1.1rem;
  color: var(--primary-blue);
  font-weight: bold;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.my-profile-card {
  border-radius: 1rem;
  overflow: hidden;
}

.header-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  position: relative;
  margin-top: -64px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 16px;
}

.header-col {
  display: flex;
  flex-direction: column;
}

.header-title-row {
  display: flex;
  flex-direction: row;
}

.header-image {
  width: 30%;
  flex-shrink: 1;
  float: left;
}

.my-profile-col {
  width: 40%;
  float: left;
  flex-grow: 1;
}

.col.my-profile-left {
  flex-basis: 87%;
}

.col.my-profile-right {
  flex-basis: 37%;
}

.my-profile-tabs.controlled-tabs {
  height: 50%;
  right: 45%;
  position: relative;
}

@media only screen and (max-width: 1600px) {
  .header-card {
    display: flex;
    flex-direction: column;
  }

  .header-col {
    display: flex;
    /*  flex-direction: column; */
  }

  .header-col {
    display: flex;
    flex-direction: row;
  }

  .my-profile-col {
    width: 40%;
    float: left;
    flex-grow: 1;
  }

  .my-profile-left {
    flex-basis: 56%;
  }

  /* .col.my-profile-right {
    flex-basis: 33%;
  } */

  .header-title-row {
    width: 100%;
  }

  .my-profile-tabs.controlled-tabs {
    right: 36%;
  }
}

.my-profile-card .card-body {
  min-height: 1px;
  padding: 1.25rem;
  flex: 0 0 auto;
}

:where(.css-dev-only-do-not-override-15rg2km).ant-avatar.user-pic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
