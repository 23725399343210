.overview-table p.email {
  margin: 0;
  font-size: 14px;
  font-style: italic;
  color: #535556;
}

svg.fa-eye.disabled {
  color: #7e7e7e;
}
