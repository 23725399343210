.appraisal-preview {
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.appraisal-preview .header {
  padding-bottom: 0.3125rem;
  border-bottom: 0.0625rem solid #c1c1c1;
}

.appraisal-preview .header > .goals-header {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}
.appraisal-preview .table-header {
  border-bottom: 1px solid #c1c1c1;
}

.appraisal-preview .header,
.appraisal-preview .goals-header {
  color: var(--primary-light);
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

.gray-heading {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 200;
  text-align: center;
  text-transform: none;
  padding: 0;
}

.appraisal-preview-row {
  border-bottom: 0.0625rem solid #c1c1c1;
  min-height: 60px !important;
  font-size: 0.875rem;
}

.appraisal-preview-row h5 {
  font-weight: 600;
}

.appraisal-row:first-child {
  margin-top: 0;
}

.appraisal-row {
  border-bottom: none;
  margin-top: 1rem;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.appraisal-preview p.goal-desc {
  margin-bottom: 0.125rem;
  font-size: 0.875rem;
  color: #535556;
}

.appraisal-preview .content {
  background-color: #fff;
  padding: 0 0.5rem;
}

.appraisal-preview .goal-inactive {
  color: #c1c1c1;
}

.appraisal-preview .bordered {
  border-right: 1px solid #c1c1c1;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.appraisal-preview .read-only {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.appraisal-preview .full-height {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: col;
  -ms-flex-direction: col;
  flex-direction: col;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: left;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.appraisal-preview .full-height-centered {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.appraisal-preview textarea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.appraisal-preview .comment_pd {
  white-space: pre-line;
}

.appraisal-preview p.comment_pd {
  white-space: pre-line;
  margin: 15px 20px;
}

.appraisal-preview .appraisal-preview-row .weight-column {
  color: var(--primary-light);
  font-size: 1.25rem;
  padding: 0;
}

.appraisal-preview [active="false"],
.appraisal-preview [active="false"] .goal-desc,
.appraisal-preview [active="false"] .appraisal-preview-row .weight-column {
  color: #c1c1c1;
}

.appraisal-preview svg.fa-comment-alt.disabled {
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 7px;
}

.appraisal-preview .row div[data-id="tooltip"] {
  text-transform: none;
  font-weight: normal;
}

.appraisal-preview div.place-left.type-dark[data-id="tooltip"] {
  max-width: 60%;
  z-index: 1040;
}

.appraisal-preview .comment-row h6 {
  font-weight: 600;
}

.comment-row.review-comment {
  border-bottom: 0;
}

.appraisal-preview .actions {
  position: absolute;
}

.custom-checkbox > .form-check > .form-check-input {
  height: 30px;
  width: 30px;
  right: 15rem;
}
.custom-checkbox > .form-check > .form-check-label {
  text-align: center;
  font-size: 20px;
  color: grey;
}
