.inactive {
  color: #c1c1c1;
}

a svg {
  color: #444444;
}

a svg:hover {
  color: #000000;
  cursor: pointer;
}
