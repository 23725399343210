.questionBox {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: white;
  color: white;
}

.comment-display-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0 1rem 0.25rem;
  border: none;
}

.image-wrapper + div {
  display: flex;
  width: 100%;
}

.comment-display-wrapper .image-wrapper {
  margin-right: 1rem;
}

.comment-display-wrapper .image-wrapper svg,
.comment-display-wrapper .image-wrapper img {
  width: 50px;
}

.comment-display-wrapper ::before {
  /*  content: ""; */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #6691ff26;
  position: absolute;
  top: 15px;
  left: -10px;
}

.comment-display-wrapper .card-body {
  padding: 1rem;
}

.comment-display-wrapper .card-body .card-title {
  font-size: 1rem;
  font-weight: 600;
}
