.controlled-tabs a.nav-item.nav-link {
  border: transparent;
  color: var(--primary-blue);
  margin-bottom: 0.5px;
}

.controlled-tabs a.nav-item.nav-link:hover,
.controlled-tabs a.nav-item.nav-link:focus,
.controlled-tabs a.nav-item.nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid var(--primary-light);
}

.controlled-tabs a.nav-item.nav-link.active {
  font-weight: 600;
}
