.row.data {
  /* position: fixed; */
  bottom: 0;
  margin: auto 1rem auto 1%;
  font-size: 0.75em;
  display: flex;
  flex-direction: row;
  width: 90%;
}

.row.data p {
  float: left;
  width: 89%;
  margin-top: 2%;
  color: grey;
}

.row.data a {
  float: right;
  width: 10%;
}
