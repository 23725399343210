.main-row {
  margin-top: 80px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  background-color: white;
}

.trainings-title {
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 25%;
  top: 2%;
}
.training-statuses {
  padding-left: 80px;
  padding-top: 530px;
  margin-bottom: 50px;
}
.trainings-content {
  padding-left: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.status-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.status-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.rectangle-col {
  text-align: center;
  font-size: 18px;
}

.months-col {
  border-left: solid;
  padding-top: 30px;
}

.months-title {
  text-align: center;
  font-weight: bold;
}
.training-status-rect {
  height: 80px;
  width: 130px;
  background-color: #bdd7ee;
  border-radius: 10px;
  margin-bottom: 20px;
}
.bar-column {
  border-left: solid;
}
.training-status-label {
  text-align: center;
  font-size: 18px;
}
.training-status-count {
  font-weight: bold;
}
.training-status-column {
  padding-top: 140px;
}
.doughnut-column {
  padding-top: 30px;
}
.status-container {
  display: flex;
}
.status-label {
  margin-right: 5px;
}
.percentage-value {
  font-weight: bold;
  display: "inline-block";
}
.doughnut-canvas {
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pie-chart {
  margin-top: 80px;
}
.bar-chart {
  height: 600px;
}
