.oneOnOne.filter {
  border: none;
  margin-left: 3%;
  margin-top: 2%;
}

.oneOnOneByRole.filter {
  border: none;
  margin-left: 3%;
  margin-top: 2%;
}

.setOneOnOne.btn.btn-primary {
  font-size: 1.1rem;
  justify-content: right;
}

.oneOnOne-label {
  padding-left: 2%;
}

@media only screen and (max-width: 1600px) {
  .setOneOnOne.btn.btn-primary {
    font-size: 0.95rem;
  }
}
