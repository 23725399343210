table.processes {
  margin: auto;
}

table.processes > thead > tr,
table.processes > tbody > tr {
  border-bottom: 0.5px solid #c1c1c1;
}

table.processes > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  text-align: center;
}

table.processes > thead > tr > th:first-child,
table.processes > tbody > tr > td:first-child {
  text-align: left;
}

table.processes > thead > tr > th:last-child,
table.processes > tbody > tr > td:last-child {
  text-align: right;
}

table.processes > tbody > tr {
  background-color: #fff;
  height: 6.5rem;
}

table.processes > tbody > tr > td {
  padding: 0.75rem 1rem 1.875rem 1rem;
  text-align: center;
}

table.processes > tbody > tr > td:first-child {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

table.processes > tbody > tr > td > .process-completion {
  position: relative;
  top: 0;
  left: 0;
  width: 4.25rem;
  max-height: 4.25rem;
}

table.processes > tbody > tr > td > .process-completion > .CircularProgressbar {
  position: relative;
  top: 0;
  left: 0;
}

table.processes > tbody > tr > td > .process-completion > div {
  position: relative;
  top: -3.9rem;
  left: 0.625rem;
  width: 3rem;
}

table.processes > tbody > tr > td > .process-completion > div > div {
  font-size: 1rem;
  text-align: center;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  > div
  > .process-completed-participants {
  color: var(--primary-light);
  font-size: 1.2rem;
  font-weight: bold;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  > div
  > .process-participants {
  border-top: 1px solid silver;
  color: silver;
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  .CircularProgressbar
  > .CircularProgressbar-path {
  stroke: var(--primary-light);
}

table.processes
  > tbody
  > tr
  > td
  > .process-completion
  .CircularProgressbar
  > .CircularProgressbar-text {
  fill: var(--primary-light);
  font-size: 2rem;
}

table.processes > tbody > tr > td > .process-info {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 82.5%;
}

table.processes > tbody > tr > td > .process-info > .process-title > strong,
table.processes > tbody > tr > td > .process-days-left {
  font-size: 1rem;
}

table.processes > tbody > tr > td > .process-info > .process-title > span,
table.processes > tbody > tr > td > .process-info > .process-managed-by,
table.processes > tbody > tr > td > .process-period {
  font-size: 0.925rem;
}

table.processes > tbody > tr > td > .process-info > .process-managed-by {
  margin-top: 0.125rem;
}

table.processes > tbody > tr > td > .process-days-left {
  font-weight: bold;
  min-height: 1.5rem;
}

table.processes > tbody > tr > td > .process-days-left.green {
  color: green;
}

table.processes > tbody > tr > td > .process-days-left.yellow {
  color: gold;
}

table.processes > tbody > tr > td > .process-days-left.red {
  color: red;
}

table.processes
  > tbody
  > tr
  > td
  > .process-info
  > .process-managed-by
  > span:first-child,
table.processes > tbody > tr > td > .process-period {
  color: darkgray;
}

table.processes > tbody > tr > td .actions {
  position: absolute;
}

table.processes > tbody > tr > td:first-child .actions {
  margin: 3.75rem 0 0 5.55rem;
}

table.processes > tbody > tr > td:last-child .actions:lang(en) {
  margin: 0;
}

table.processes > tbody > tr > td:last-child .actions:lang(fr) {
  margin: 0;
}

table.processes > tbody > tr > td .actions:lang(bg) {
  margin-left: -3%;
  margin-top: 5px;
}

table.processes > tbody > tr > td .process.actions:lang(bg) {
  margin-left: 0;
  margin-top: 66px;
}

table.processes > tbody > tr > td .actions > .btn-link {
  padding: 0 1rem 0 0;
}

table.processes > tbody > tr > td .actions > .btn-link:last-child {
  padding: 0;
}

table.processes > tbody > tr > td .actions > .dropdown-toggle::after {
  content: none;
}
