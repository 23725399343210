.sidebar-right {
  background-color: #fff;
  position: fixed;
  top: 8%;
  right: 2px;
  width: 13rem;
  /*   height: 84%;
  float: right;
  padding-top: 75px; */
  float: right;
  z-index: -1;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid gray;
  margin-top: 6px;
}

@media only screen and (max-width: 1600px) {
  .sidebar-right {
    top: 10%;
  }
}

@media only screen and (max-width: 1400px) and (max-height: 850px) {
  .sidebar-right {
    top: 15%;
  }
}

.sidebar-right .row .col-md-6 figure.figure.user-profile-picture {
  display: inline-block;
  position: inherit;
}

.sidebar-right
  .row
  .col-md-6
  figure.figure.user-profile-picture
  .profile-image {
  width: 100%;
  height: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: 3%;
}

.sidebar-right .row .user-data {
  padding: 2rem 0 0 0;
}

.sidebar-right .row .user-data b.names {
  font-size: 1em;
}

.sidebar-right .row .user-data p {
  font-size: 1em;
  font-weight: normal;
}

.sidebar-right .row .user-data p.role-name {
  text-transform: capitalize;
}

.sidebar-right .row .user-data .buttons {
  margin-top: 40px;
}

.sidebar-right .row .user-data .buttons button.btn-link {
  padding: 0;
}
