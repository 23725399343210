@import url("https://fonts.googleapis.com/css?family=Crete+Round&display=swap");
/* Colors used troughout the app */
:root {
  --primary-darkest-blue: #1b1c34;
  --primary-dark-blue: #202240;
  --primary-blue: #24274c;
  --primary-lightest-blue: #696784;
  --primary-dark: #2d2f66;
  --primary-light: #6691ff;
  --primary-blue-circle: #2950b3;
  --secondary-dark-blue: #2d2f66;
  --secondary-light-gray: #eeeeee;
  --alternative-pinky-red: #dd1273;
  --popover-light-yellow: #fffeca;
  --breadcrumb-text-color: #fff;
  --light-grey: #a6a6a6;
}

/* @font-face {
  font-family: "SpartanBold";
  src: url("./fonts/Spartan-Bold.ttf");
} */

@font-face {
  font-family: "Lato";
  src: url("fonts/Lato-Regular.ttf");
}

body {
  background-color: #faf9f8;
  font-family: "Lato";
}

/* h1,
h2,
h3,
h4,
h5,
h6,
.modal-title {
  font-family: "SpartanBold";
} */

.badge.badge-pill.badge-primary {
  background-color: var(--primary-darkest-blue);
}

.container {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3.125rem;
  min-height: 78vh;
}

.validation-error-msg {
  color: red;
  font-size: 12px;
  margin-top: 4%;
}

[class="custom-control-input"]:checked
  ~ [class="custom-control-label"]::before {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
}

u.tooltip-dashed {
  border-bottom: 1px dashed #8a8a8a;
  text-decoration: none;
}

select.assessment {
  width: 190px;
}

table tbody tr td {
  vertical-align: middle !important;
}

table tbody tr td.profile-img {
  vertical-align: middle !important;
  width: 4.25rem;
  padding-right: 0;
}

table tbody tr td img {
  height: 50px;
}

.btn-primary {
  /* background-color: #236eaf; */
  /* border-color: #236eaf; */
  background-color: var(--primary-light);
  border-color: var(--primary-light);
}

.btn-primary:hover {
  /* background-color: #0257a1; */
  /* border-color: #0257a1; */
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

.btn-primary:focus {
  /* background-color: #0257a1 !important; */
  /* border-color: #0257a1 !important; */
  /* box-shadow: 0 0 0 0.2rem #236eaf8e !important; */
  background-color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-light);
}

.btn-success {
  background-color: #046c61;
  border-color: #046c61;
}

.btn-success:hover {
  background-color: #045850;
  border-color: #045850;
}

.btn-success:focus {
  background-color: #045850 !important;
  border-color: #045850 !important;
  box-shadow: 0 0 0 0.2rem #046c618e !important;
}

.btn-outline-success {
  color: #046c61;
  border-color: #046c61;
}

.btn-outline-success:disabled {
  color: #046c61;
  border-color: #046c61;
}

.btn-outline-success:hover {
  background-color: #046c61;
  border-color: #046c61;
  color: #ffffff;
}

.btn-outline-success:focus {
  background-color: #046c61 !important;
  border-color: #046c61 !important;
  color: #ffffff;
  box-shadow: 0 0 0 0.2rem #046c618e !important;
}

.btn-danger {
  /* background-color: #a40080; */
  /* border-color: #a40080; */
  background-color: #dd1273;
  border-color: #dd1273;
}

.btn-danger:hover {
  /* background-color: #850068; */
  /* border-color: #850068; */
  background-color: #b00e5c;
  border-color: #b00e5c;
}

.btn-danger:focus {
  /* background-color: #850068 !important; */
  /* border-color: #850068 !important; */
  /* box-shadow: 0 0 0 0.2rem #a400808e !important; */
  background-color: #b00e5c;
  border-color: #b00e5c;
  box-shadow: 0 0 0 0.2rem #dd1273;
}

.btn-outline-danger {
  /* color: #a40080; */
  /* border-color: #a40080; */
  color: #dd1273;
  border-color: #dd1273;
}

.btn-outline-danger:hover {
  /* background-color: #a40080; */
  /* border-color: #a40080; */
  background-color: #dd1273;
  border-color: #dd1273;
  color: #ffffff;
}

.btn-outline-danger:focus {
  /* background-color: #a40080 !important; */
  /* border-color: #a40080 !important; */
  /* box-shadow: 0 0 0 0.2rem #a400808e !important; */
  background-color: #dd1273 !important;
  border-color: #dd1273 !important;
  box-shadow: 0 0 0 0.2rem #b00e5c;
  color: #ffffff;
}

.btn-secondary {
  background-color: #808285;
  border-color: #808285;
}

.btn-secondary:hover {
  background-color: #6f7174;
  border-color: #6f7174;
}

.btn-secondary:focus {
  background-color: #6f7174;
  border-color: #6f7174;
  box-shadow: 0 0 0 0.2rem #8082858e;
}

.btn-outline-secondary {
  color: #808285;
  border-color: #808285;
}

.btn-outline-secondary:disabled {
  color: #808285;
  border-color: #808285;
}

.btn-outline-secondary:hover {
  background-color: #808285;
  color: #ffffff;
  border-color: #808285;
}

.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem #8082858e !important;
}

.btn-outline-primary {
  color: var(--primary-light);
  border-color: var(--primary-light);
}

.btn-outline-primary:disabled {
  color: var(--primary-light);
  border-color: var(--primary-light);
}

.btn-outline-primary:hover {
  background-color: var(--primary-light);
  color: #ffffff;
  border-color: var(--primary-light);
}

.btn-outline-primary:active {
  background-color: var(--primary-light) !important;
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.0625rem var(--primary-light) !important;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
}

.btn-outline-info {
  color: #4baeed;
  border-color: #4baeed;
}

.btn-outline-info:hover {
  background-color: #4baeed;
  color: #ffffff;
  border-color: #4baeed;
}

.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem #4baeed8e !important;
}

.btn-outline-dark {
  border-color: var(--secondary-dark-blue);
  color: var(--secondary-dark-blue);
}

.btn-outline-dark:hover {
  background-color: var(--secondary-dark-blue);
  border-color: var(--secondary-dark-blue);
  color: #fff;
}

a,
.btn-link {
  background-color: inherit;
  color: var(--primary-light);
}

a:focus,
a:hover,
.btn-link:focus,
.btn-link:hover {
  color: var(--primary-dark);
  text-decoration: none;
}

a:focus svg > path,
a:hover svg > path,
.btn-link:focus svg > path,
.btn-link:hover svg > path {
  fill: var(--primary-dark);
}

.btn-link.disabled,
.btn-link:disabled {
  text-decoration: line-through;
}

.sort {
  width: fit-content;
  color: #000000;
  font-weight: 700;
  cursor: pointer !important;
}

div.progress-bar {
  background-color: var(--primary-light) !important;
}

.iframe-aad {
  margin: 0 auto;
  z-index: 1000;
  top: 100px;
  position: relative;
  visibility: hidden;
}

.bs-popover-top > .arrow::after {
  border-top-color: var(--popover-light-yellow);
}

.bs-popover-right > .arrow::after {
  border-right-color: var(--popover-light-yellow);
}

.popover {
  background-color: var(--popover-light-yellow);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.17);
}

.flex-display-wrap {
  display: flex;
  flex-wrap: wrap;
}

.half-width {
  width: 50%;
}

.three-column-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 1rem;
}

button.btn {
  border-radius: 20px;
}

a.btn {
  border-radius: 20px;
}

svg.fa-trash-alt {
  margin: 0 !important;
}

.text-area {
  resize: none;
  /*   overflow: hidden; */
  min-height: 30px;
  white-space: pre-line;
  text-align: justify;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
}
.container-relative {
  position: relative;
}
