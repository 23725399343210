.post-final img {
  width: 200px;
}

.post-final h5 {
  margin: 0.625rem 0 0.625rem 0;
}

.post-final .right div.text {
  font-size: 1.125rem;
  padding-left: 1rem;
}

.post-final .mt-auto {
  margin-top: auto;
}

.post-final .mb-auto {
  margin-bottom: auto;
}

.post-final button.mr-auto {
  margin-right: auto;
  margin-left: 18px;
  width: 199px;
}

.post-final button.ml-auto {
  margin-left: auto;
  margin-right: 18px;
  width: 199px;
}

.post-final button p {
  margin-bottom: 0;
}

.post-final svg.fa-check {
  color: #109717;
}

.post-final svg.fa-question-circle {
  color: #0994d4;
}

.post-final svg.fa-exclamation-triangle {
  color: #ccaa3a;
}

.post-final .centered {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
