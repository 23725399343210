table.template-groups {
  align-self: center;
  width: 100%;
}

table.template-groups > thead > tr,
table.template-groups > tbody > tr {
  border-bottom: 1px solid #c1c1c1;
}

table.template-groups > thead > tr > th {
  color: #7d7c7c;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.3125rem 0.75rem;
  text-align: center;
}

table.template-groups > thead > tr > th > .btn-plus {
  margin-left: 1rem;
  vertical-align: sub;
}

table.template-groups > thead > tr > th:first-child,
table.template-groups > tbody > tr > td:first-child {
  text-align: left;
}

table.template-groups > tbody > tr {
  background-color: #fff;
  font-size: 1rem;
  height: 5rem;
}

.table.template-groups > tbody > tr > td {
  padding: 0.25rem 1rem 1.75rem 1rem;
  text-align: center;
}

table.template-groups > tbody > tr > td > .actions {
  position: absolute;
}

table.template-groups > tbody > tr > td > .actions > .btn-link {
  font-size: 1rem;
  padding: 0 1rem 0 0;
}
